/* eslint-disable max-lines-per-function */
import { SvgIcon } from '@progress/kendo-react-common';
import { downloadIcon, moreHorizontalIcon, redoIcon } from '@progress/kendo-svg-icons';
import { useTranslation } from 'react-i18next';
import './message.css';
import '../Conversation/conversation.css';
import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { preventPropagationToParent } from '../../common/messageUtil';
import { ScrollView } from '@progress/kendo-react-scrollview';
import MessageDetails from '../MessageDetails/MessageDetails';
import { GlobalInfoContext } from '../../Contexts/globalInfoContext';
import { formatToRelativeDate, formatToMessageStatusAndDate } from '../../common/messageInfoFormat';
import { Dialog } from '@progress/kendo-react-dialogs';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { IgetAllMessageData } from '../Conversation/IConversation';
import {
    createUsersApi,
    createMessagesApi,
    ApiMsConversations,
    buildLogsAndPostToIndexedDB,
    settings,
    useAuth,
    createConversationsDocumentsApi,
} from 'idside-core';
import { ContextMenu, MenuItem, MenuSelectEvent } from '@progress/kendo-react-layout';
import {
    MessageContextMenuActionTypeEnum,
    DataForContextMenuAction,
    MimeTypeEnum,
    PREFIX_DISPLAY_BASE_64_IMAGE,
    PREFIX_DISPLAY_BASE_64,
    IMAGE_NAME_DOWNLOAD,
    APPLICATION,
    TEXT,
    IMAGE_MIME_TYPE,
    PREFIX_DISPLAY_DATA,
    isAnImageMimeType,
    isAnInternalDocumentMimeType,
} from '../commonData';
import { Buffer } from 'buffer';
import MessageDocument from '../MessageDocument/MessageDocument';
import getFileExtension from '../../common/FileExtension';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import MessageImageDownloader, { getCurrentImage } from './MessageImageDownloader';
import { blockContextMenu } from '../../common/imageElementUtil';
import { downloadFiles, loadDocumentDataIfNeeded } from '../../common/DownloadFile';
import { loadCurrentImageIfNeeded, registerInoutHandlers } from '../../common/scrollViewUtil';

export type MessageContent = {
    id: string;
    user: string;
    message: string;
    documents: Document[];
    createdDate: string;
    decrypted: boolean;
    recipients: any;
    authorId: string;
    accountId: string;
    impersonatorId: string;
    interlocutorReceivedDate: string;
    interlocutorReadDate: string;
    currentUserReadDate: string;
    readCount: number;
    impersonatorName: string;
    messageAutorFullName: string;
};

export interface DocumentDataRequest extends ApiMsConversations.MessageDocumentRequest {}

export interface Document extends ApiMsConversations.MessageDocumentResponse {
    mimeType: string;
    size?: number;
    copyInitiator?: string;
}

type MessageProps = {
    messageData: MessageContent;
    onCopy: (message: MessageContent) => void;
    onReply: (message: MessageContent) => void;
    onForward: (message: MessageContent) => void;
    isGroupConversation: boolean;
    readMessage: (e: any) => Promise<void>;
    messageImpersonatorName: IgetAllMessageData[] | undefined;
    impersonatorIdList: string[] | undefined;
    clicked: boolean;
    setClickedButton: (clicked: boolean) => void;
    OnScollToInitialMessage: (originalMessageId: any) => void;
    messageRef: any;
    setDisplaySearchModal: Dispatch<SetStateAction<boolean>>;
};

const Message = ({
    messageData,
    onCopy,
    onReply,
    onForward,
    isGroupConversation,
    readMessage,
    messageImpersonatorName,
    clicked,
    setClickedButton,
    OnScollToInitialMessage,
    messageRef,
    setDisplaySearchModal,
}: MessageProps) => {
    const {
        orgID: currentOrganisationId,
        currentUserId,
        accountId,
        impersonatorName: accountName,
        setError,
        setErrorContent,
    } = useContext(GlobalInfoContext);
    const [t] = useTranslation(['common', 'message', 'error']);
    const [visible, setVisible] = useState<boolean>(false);
    const [visibleDetailMessage, setVisibleDetailMessage] = useState<boolean>(false);
    const [isCurrentUserMessage, setIsCurrentUserMessage] = useState<boolean>(false);
    const [_, setIsCurrentOtherMessage] = useState<boolean>(false);
    const [beenRead, setBeenRead] = useState<string>('');
    const [impersonatorName, setImpersonatorName] = useState<string>('');
    const offset = useRef({ left: 0, top: 0 });
    const [show, setShow] = useState(false);
    const [imageList, setImageList] = useState<Document[]>([]);
    const [documentList, setDocumentList] = useState<Document[]>([]);
    const [replyImageList, setReplyImageList] = useState<Document[]>([]);
    const [replyDocumentList, setReplyDocumentList] = useState<Document[]>([]);
    const [forwardImageList, setForwardImageList] = useState<Document[]>([]);
    const [forwardDocumentList, setForwardDocumentList] = useState<Document[]>([]);
    const [messageReplyTo, setMessageReplyTo] = useState<undefined | any>();
    const [forwardedMessage, setForwardedMessage] = useState<any>();
    const [viewImageList, setViewImageList] = useState<Document[]>([]);
    const [scrollViewImageIndex, setScrollViewImageIndex] = useState<number>(0);
    const [documentCount, setDocumentCount] = useState<number>(0);
    const [isReplyOfForwardMessage, setIsReplyOfForwardMessage] = useState<boolean>(false);
    const { getToken, authInstance } = useAuth();
    const [docsLoading, isDocsLoading] = useState<boolean>(false);
    const scrollViewRef = useRef<any>(null);
    const PopupMenuItems = DataForContextMenuAction.map((d, i) => (
        <MenuItem
            key={i}
            cssClass={d.class}
            text={t(d.text, { ns: 'common' })}
            icon={d.icon}
            data={{ action: d.action }}
        />
    ));

    const onClose = () => {
        setError(false);
    };

    // Open the image viewer with the list of specified document (they must be images)
    // 
    // Parameters
    // list : List of images to be available in the image viewer
    // imageIndex : Specify the zero-based index of the image that will be shown first
    // eventPropagationToStop (optionnal) : Specify an event for whitch you wish to preventfrom being propagated to other elements.
    const onOpenImageViewerClicked = async (imageIndex: number, list: Document[] = [], eventPropagationToStop: any = null) => {
        if (eventPropagationToStop) {
            preventPropagationToParent(eventPropagationToStop);
        }

        //TODO: refactoring add Document[] to list and the forward and replay states
        setViewImageList(list);
        setScrollViewImageIndex(imageIndex);
        isDocsLoading(true);
        setVisible(true);
        setViewImageList(
            await loadDocumentDataIfNeeded(list, imageIndex, currentOrganisationId, currentUserId, authInstance),
        );

        isDocsLoading(false);
    };

    const onCloseImageViewerClicked = () => {
        setVisible(false);
    };

    const handleContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
        offset.current = { left: e.pageX, top: e.pageY };

        e.preventDefault();
        setShow(true);
    };

    const handleOnSelect = (event: MenuSelectEvent) => {
        messageData.impersonatorName = impersonatorName;
        messageData.messageAutorFullName = getAuthorFullName();
        switch (event.item.data.action) {
            case MessageContextMenuActionTypeEnum.Copy:
                onCopy(messageData);
                break;
            case MessageContextMenuActionTypeEnum.Reply:
                onReply(messageData);
                break;
            case MessageContextMenuActionTypeEnum.Forward:
                onForward(messageData);
                setDisplaySearchModal(true);
                break;
            default:
        }

        setShow(false);
    };

    const handleCloseMenu = () => {
        setShow(false);
    };

    const updateMessageReplyTo = () => {
        // TODO: uncomment line below to test with document mocks
        // messageData = { ...messageData, documents: MessageMockDownloader.documents };
        if (messageData && messageData.documents) {
            let copyOfDocuments: Document[] = messageData.documents.slice();

            let base64MessageIndex = copyOfDocuments.findIndex((e) => e.mimeType === MimeTypeEnum.REPLY); //Dans le cas ou on a un reply de forward

            if (base64MessageIndex !== -1) {
                const index = copyOfDocuments.findIndex((e) => e.mimeType === MimeTypeEnum.FORWARD);

                if (index !== -1) {
                    base64MessageIndex = index;
                }
            }

            if (base64MessageIndex !== -1) {
                const base64Message = copyOfDocuments.splice(base64MessageIndex, 1)[0];
                if (base64Message.data) {
                    setMessageReplyTo(JSON.parse(Buffer.from(base64Message.data, 'base64').toString()));
                }
            }
            let tempImageList: Document[] = [];
            let tempDocumentList: Document[] = [];
            let tempReplyImageList: Document[] = [];
            let tempReplyDocumentList: Document[] = [];

            //TODO: Uncomment and modify later to add documents to the forward.
            // copyOfDocuments.forEach((e) => {
            //     if (e.mimeType !== MimeTypeEnum.REPLY && e.mimeType !== MimeTypeEnum.FORWARD) {
            //         if (e.referenceMessageId === undefined) tempImageList.push(e.data);
            //         else tempReplyImageList.push(e.data);
            //     }
            // });
            copyOfDocuments.forEach((e) => {
                if (e.mimeType !== MimeTypeEnum.REPLY && e.mimeType !== MimeTypeEnum.FORWARD) {
                    if (e.mimeType.startsWith(IMAGE_MIME_TYPE)) {
                        if (e.referenceMessageId === undefined) {
                            tempImageList.push(e);
                        } else {
                            tempReplyImageList.push(e);
                            // // add images in replyDocumentList
                            // tempReplyDocumentList.push(e);
                        }
                    }
                }
                if (e.mimeType.startsWith(APPLICATION) || e.mimeType.startsWith(TEXT)) {
                    if (e.referenceMessageId === undefined) {
                        tempDocumentList.push(e);
                    } else {
                        tempReplyDocumentList.push(e);
                    }
                }
            });
            setDocumentList(tempDocumentList);
            setReplyDocumentList(tempReplyDocumentList);
            setImageList(tempImageList);
            setReplyImageList(tempReplyImageList);
            setIsReplyOfForwardMessage(
                copyOfDocuments.findIndex(
                    (e: Document) => e.mimeType === MimeTypeEnum.FORWARD && e.referenceMessageId !== undefined,
                ) >= 0,
            );
        }
    };

    const updateForwardedMessage = () => {
        if (messageData && messageData.documents) {
            let copyOfDocuments = messageData.documents.slice();
            const base64MessageIndex = copyOfDocuments.findIndex(
                (e) => e.mimeType === MimeTypeEnum.FORWARD && e.referenceMessageId === undefined,
            );

            if (base64MessageIndex !== -1) {
                const base64Message = copyOfDocuments.splice(base64MessageIndex, 1)[0];
                if (base64Message.data) {
                    setForwardedMessage(JSON.parse(Buffer.from(base64Message.data, 'base64').toString()));
                }
            }

            let tempForwardImageList: Document[] = [];
            let tempForwardDocumentList: Document[] = [];
            copyOfDocuments.forEach((e) => {
                if (isAnImageMimeType(e.mimeType)) {
                    tempForwardImageList.push(e);
                } else if (!isAnInternalDocumentMimeType(e.mimeType)) {
                    tempForwardDocumentList.push(e);
                }
            });

            setForwardDocumentList(tempForwardDocumentList);
            const countDocumentTypes = (array: Document[]) => {
                return array.filter((item) => !item.mimeType.startsWith(IMAGE_MIME_TYPE)).length;
            };
            const documentCountLength = countDocumentTypes(copyOfDocuments);
            setDocumentCount(documentCountLength);
            setForwardImageList(tempForwardImageList);
        }
    };

    const getAuthorFullName = () => {
        return messageData.user + (messageData.authorId !== messageData.impersonatorId ? ` (${impersonatorName})` : '');
    };

    useEffect(() => {
        updateMessageReplyTo();
        updateForwardedMessage();
    }, [messageData]);

    useEffect(() => {
        messageData.recipients?.forEach((recipient: ApiMsConversations.MessageRecipientResponse) => {
            if (recipient.id === currentUserId) {
                if (!recipient.readDate) {
                    setBeenRead('non-read-message');
                } else if (recipient.readDate && recipient.receivedDate) {
                    setBeenRead('');
                    setClickedButton(false);
                }
            }
        });
    }, [clicked, messageData, messageData.currentUserReadDate]);

    const onScrollViewUserInput = () => {
        if (setViewImageList) {
            loadCurrentImageIfNeeded(
                scrollViewRef,
                viewImageList,
                setViewImageList,
                loadDocumentDataIfNeeded,
                currentOrganisationId,
                currentUserId,
                authInstance,
            );
        }
    };

    useEffect(() => {
        registerInoutHandlers(scrollViewRef, onScrollViewUserInput);
    }, [scrollViewRef.current?.element]);

    //If a user is not part of the conversation
    useEffect(() => {
        if (messageData.user === undefined) {
            createUsersApi(authInstance!, currentOrganisationId)
                .getUserSummaryById(messageData.authorId, currentOrganisationId)
                .then((res) => {
                    if (res.data.name) {
                        messageData.user = res.data.name;
                    }
                });
        }
    }, []);

    useEffect(() => {
        const updateMessageRecipient = async (messageDataToUpdate: ApiMsConversations.MessageUpdateRequest) => {
            const start = new Date().getTime();
            try {
                createMessagesApi(authInstance!).modifyRecipientMessage(
                    currentOrganisationId,
                    messageData.id,
                    currentUserId,
                    messageDataToUpdate,
                );
            } catch (err: any) {
                await buildLogsAndPostToIndexedDB(
                    err.message,
                    err.stack,
                    new Date().getTime() - start,
                    settings.appId,
                    window.location.origin,
                    err.config.headers.trackingId,
                    err.config[`axios-retry`].retries,
                    authInstance?.sessionId!,
                    currentUserId,
                    err.config.headers.deviceId,
                    err.config.headers.organisationId,
                    err.config.headers.provider,
                    settings.dbName,
                    settings.storeIdTexto,
                );
                <ErrorMessage onClose={onClose} />;
                setError(true);
                setErrorContent(err);
            }
        };
        const recipientID = messageData?.recipients?.find(
            (recipient: ApiMsConversations.MessageRecipientResponse) => currentUserId === recipient.id,
        );
        if (recipientID !== undefined) {
            if (currentUserId) {
                setIsCurrentUserMessage(currentUserId === messageData.authorId);
                setIsCurrentOtherMessage(currentUserId !== recipientID.id);
            }
        }

        const receivedDate = messageData?.recipients?.find((res: ApiMsConversations.MessageRecipientResponse) => {
            return res.receivedDate;
        });
        const readDate = messageData?.recipients?.find((res: ApiMsConversations.MessageRecipientResponse) => {
            return res.readDate;
        });
        if (isCurrentUserMessage) {
            //Set message as read for the currentUser for conversation between 2 people
            if (!receivedDate || !readDate) {
                let data: { recipientId: string; readMessage: true; sentMessage?: boolean };
                data = { recipientId: currentUserId, readMessage: true };

                if (messageData.interlocutorReceivedDate === undefined) {
                    data.sentMessage = true;
                }

                if (getToken()) {
                    updateMessageRecipient(data);
                }
            }
        }
    }, [currentUserId, accountId]);

    useEffect(() => {
        if (messageData.impersonatorId !== messageData.authorId) {
            if (messageData.impersonatorId === accountId) {
                setImpersonatorName(accountName);
            } else {
                const messageImpersonator = messageImpersonatorName?.find(
                    (assignImpersonatorName) => assignImpersonatorName.id === messageData.impersonatorId,
                );
                if (messageImpersonator && messageImpersonator.name) {
                    setImpersonatorName(messageImpersonator.name);
                }
            }
        }
    }, [
        accountId,
        accountName,
        messageData.authorId,
        messageData.impersonatorId,
        messageImpersonatorName,
        messageImpersonatorName?.length,
    ]);

    function onDownloadImageClicked(): void {
        let currentImage = getCurrentImage(viewImageList);
        const documentId = currentImage.id;

        if (!messageData.id || !documentId) {
            throw new Error('Invalid document');
        }

        createConversationsDocumentsApi(authInstance, currentOrganisationId).postDocumentExport({
            userId: currentUserId,
            messageId: messageData.id,
            documentId,
        });
        MessageImageDownloader(viewImageList, IMAGE_NAME_DOWNLOAD);
    }

    return (
        <>
            {visibleDetailMessage && (
                <Dialog
                    title={t('messageDetail', { ns: 'message' })}
                    width={'75%'}
                    height={'75%'}
                    onClose={() => setVisibleDetailMessage(false)}>
                    <MessageDetails messageData={messageData} />
                </Dialog>
            )}
            <div
                className="chat-message-box"
                data-testid="chat-message-box"
                key={messageData.id}
                id={isCurrentUserMessage ? '' : 'other'}>
                <div ref={messageRef} className="k-bubble-box" data-testid="k-bubble-box">
                    <div className="chat-bubble-top-info-section">
                        <div
                            data-testid="chat-message-author"
                            className={isCurrentUserMessage ? 'k-author' : 'k-author other-author'}
                            id={messageData.id}>
                            {getAuthorFullName()}
                        </div>
                        <div
                            className="dc-time"
                            data-testid="send-time"
                            id={isCurrentUserMessage ? '' : 'other-dc-time'}>
                            {formatToRelativeDate(messageData.createdDate, undefined, false)}
                        </div>
                    </div>
                    <div
                        className={`chat-bubble ${beenRead}`}
                        id={isCurrentUserMessage ? '' : 'other-chat-bubble'}
                        data-testid="other-chat-bubble">
                        <div className="message-content" data-testid="message-content" onClick={readMessage}>
                            {/*TODO: Optimization: Array of image could be potentially pre-calculated*/}

                            {messageReplyTo && (
                                <div
                                    className="message-text-being-replied-to-box displayRow"
                                    onClick={() => OnScollToInitialMessage(messageReplyTo.id)}
                                    data-testid="send-time">
                                    <div className="displayColumn silverLine">{''}</div>

                                    <div className="displayColumn">
                                        <div className="displayRow user-name-date-close-bt">
                                            <div className="displayColumn message-user-name-date">
                                                <span className="message-to-reply-to-username">
                                                    {messageReplyTo.auteur}
                                                </span>
                                                <span className="message-to-reply-to-date">
                                                    {formatToRelativeDate(messageReplyTo.createdDate, false, false)}
                                                </span>
                                            </div>
                                        </div>
                                        {(replyDocumentList.length || replyImageList.length > 0) && (
                                            <div
                                                className="message-msgdoc-container"
                                                data-testid="message-msdoc-container">
                                                {replyDocumentList.length > 0 &&
                                                    replyDocumentList.map((doc: Document, index: number) => {
                                                        // if(!isAnImageMimeType(doc.mimeType))
                                                        if (!messageData.decrypted) {
                                                            return (
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    aria-hidden="true"
                                                                    style={{
                                                                        width: '30px',
                                                                        fill: 'white',
                                                                        padding: '1px',
                                                                    }}
                                                                    viewBox="0 0 512 512">
                                                                    {/* TODO : Changer le style inline par une classe pour avoir la bonne façon de faire*/}
                                                                    <path d="M256 480c123.7 0 224-100.3 224-224S379.7 32 256 32 32 132.3 32 256s100.3 224 224 224m-32-352h64v160h-64zm0 256v-64h64v64z" />
                                                                </svg>
                                                            );
                                                        } else {
                                                            return (
                                                                <MessageDocument
                                                                    key={doc.id}
                                                                    doc={doc}
                                                                    isCurrentUserMessage={isCurrentUserMessage}
                                                                    messageId={messageData.id}
                                                                    isDownloadAvailable={true}
                                                                />
                                                            );
                                                        }
                                                    })}
                                                {replyImageList.length > 0 &&
                                                    replyImageList.map((img: Document, index: number) => {
                                                        if (!messageData.decrypted) {
                                                            return (
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    aria-hidden="true"
                                                                    style={{
                                                                        width: '30px',
                                                                        fill: 'white',
                                                                        padding: '1px',
                                                                    }}
                                                                    viewBox="0 0 512 512">
                                                                    {/* TODO : Changer le style inline par une classe pour avoir la bonne façon de faire*/}
                                                                    <path d="M256 480c123.7 0 224-100.3 224-224S379.7 32 256 32 32 132.3 32 256s100.3 224 224 224m-32-352h64v160h-64zm0 256v-64h64v64z" />
                                                                </svg>
                                                            );
                                                        } else {
                                                            return (
                                                                <img
                                                                    key={img.id}
                                                                    className="img-bubble"
                                                                    data-testid={`img-msg-${index}`}
                                                                    src={
                                                                        PREFIX_DISPLAY_DATA +
                                                                        getFileExtension(img.mimeType) +
                                                                        PREFIX_DISPLAY_BASE_64 +
                                                                        (img.thumbnail ? img.thumbnail : img.data)
                                                                    }
                                                                    alt=""
                                                                    onContextMenu={blockContextMenu}
                                                                    onClick={(event) => {
                                                                        onOpenImageViewerClicked(index, replyImageList, event)
                                                                    }}
                                                                />
                                                            );
                                                        }
                                                    })}
                                            </div>
                                        )}
                                        <div className="displayRow-message  message-c-input-text-reply">
                                            {messageReplyTo.text}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {forwardedMessage && (
                                <div
                                    className="message-text-being-replied-to-box displayRow"
                                    data-testid="message-text-being-replied-to-box">
                                    <div className="displayColumn silverLine-forward">
                                        <SvgIcon className="forwardIcon" icon={redoIcon} size="medium" />
                                    </div>

                                    <div className="displayColumn">
                                        <div className="displayRow user-name-date-close-bt">
                                            <div className="displayColumn message-user-name-date">
                                                <span
                                                    className="message-to-reply-to-username"
                                                    data-testid="message-to-reply-to-username">
                                                    {forwardedMessage.auteur}
                                                </span>
                                                <span
                                                    className="message-to-reply-to-date"
                                                    data-testid="message-forward-create-date">
                                                    {formatToRelativeDate(forwardedMessage.createdDate, false, false)}
                                                </span>
                                            </div>
                                        </div>
                                        {(forwardDocumentList.length || forwardImageList.length > 0) && (
                                            <div
                                                className="message-msgdoc-container"
                                                data-testid="message-msdoc-container">
                                                {forwardDocumentList.length > 0 &&
                                                    forwardDocumentList.map((doc: Document, index: number) => {
                                                        // if(!isAnImageMimeType(doc.mimeType))
                                                        if (!messageData.decrypted) {
                                                            return (
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    aria-hidden="true"
                                                                    style={{
                                                                        width: '30px',
                                                                        fill: 'white',
                                                                        padding: '1px',
                                                                    }}
                                                                    viewBox="0 0 512 512">
                                                                    {/* TODO : Changer le style inline par une classe pour avoir la bonne façon de faire*/}
                                                                    <path d="M256 480c123.7 0 224-100.3 224-224S379.7 32 256 32 32 132.3 32 256s100.3 224 224 224m-32-352h64v160h-64zm0 256v-64h64v64z" />
                                                                </svg>
                                                            );
                                                        } else {
                                                            return (
                                                                <MessageDocument
                                                                    key={doc.id}
                                                                    doc={doc}
                                                                    isCurrentUserMessage={isCurrentUserMessage}
                                                                    messageId={messageData.id}
                                                                    isDownloadAvailable={true}
                                                                />
                                                            );
                                                        }
                                                    })}
                                                {forwardImageList.length > 0 &&
                                                    forwardImageList.map((img: Document, index: number) => {
                                                        if (!messageData.decrypted) {
                                                            return (
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    aria-hidden="true"
                                                                    style={{
                                                                        width: '30px',
                                                                        fill: 'white',
                                                                        padding: '1px',
                                                                    }}
                                                                    viewBox="0 0 512 512">
                                                                    {/* TODO : Changer le style inline par une classe pour avoir la bonne façon de faire*/}
                                                                    <path d="M256 480c123.7 0 224-100.3 224-224S379.7 32 256 32 32 132.3 32 256s100.3 224 224 224m-32-352h64v160h-64zm0 256v-64h64v64z" />
                                                                </svg>
                                                            );
                                                        } else {
                                                            return (
                                                                <img
                                                                    key={img.id}
                                                                    className="img-bubble"
                                                                    data-testid={`img-msg-${index}`}
                                                                    onClick={() =>
                                                                        onOpenImageViewerClicked(
                                                                            index,
                                                                            forwardImageList,
                                                                        )
                                                                    }
                                                                    src={
                                                                        PREFIX_DISPLAY_DATA +
                                                                        getFileExtension(img.mimeType) +
                                                                        PREFIX_DISPLAY_BASE_64 +
                                                                        (img.thumbnail ? img.thumbnail : img.data)
                                                                    }
                                                                    alt=""
                                                                    onContextMenu={blockContextMenu}
                                                                />
                                                            );
                                                        }
                                                    })}
                                            </div>
                                        )}
                                        <div
                                            className="displayRow-message  message-c-input-text-forward"
                                            data-testid="displayRow-message">
                                            {forwardedMessage.text}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {!forwardedMessage && messageData.documents && messageData.documents.length > 0 && (
                                <div className="image-list-container" data-testid="image-list-container">
                                    {(documentList.length || imageList.length > 0) && (
                                        <div className="message-msgdoc-container" data-testid="message-msdoc-container">
                                            {documentList.length > 0 &&
                                                documentList.map((doc: Document, index: number) => {
                                                    if (!messageData.decrypted) {
                                                        return (
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                aria-hidden="true"
                                                                style={{ width: '30px', fill: 'white', padding: '1px' }}
                                                                viewBox="0 0 512 512">
                                                                {/* TODO : Changer le style inline par une classe pour avoir la bonne façon de faire*/}
                                                                <path d="M256 480c123.7 0 224-100.3 224-224S379.7 32 256 32 32 132.3 32 256s100.3 224 224 224m-32-352h64v160h-64zm0 256v-64h64v64z" />
                                                            </svg>
                                                        );
                                                    } else {
                                                        return (
                                                            <MessageDocument
                                                                key={doc.id}
                                                                doc={doc}
                                                                isCurrentUserMessage={isCurrentUserMessage}
                                                                messageId={messageData.id}
                                                                isDownloadAvailable={true}
                                                            />
                                                        );
                                                    }
                                                })}
                                            {imageList.length > 0 &&
                                                imageList.map((img: Document, index: number) => {
                                                    if (!messageData.decrypted) {
                                                        return (
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                aria-hidden="true"
                                                                style={{ width: '30px', fill: 'white', padding: '1px' }}
                                                                viewBox="0 0 512 512">
                                                                {/* TODO : Changer le style inline par une classe pour avoir la bonne façon de faire*/}
                                                                <path d="M256 480c123.7 0 224-100.3 224-224S379.7 32 256 32 32 132.3 32 256s100.3 224 224 224m-32-352h64v160h-64zm0 256v-64h64v64z" />
                                                            </svg>
                                                        );
                                                    } else {
                                                        return (
                                                            <img
                                                                key={img.id}
                                                                className="img-bubble"
                                                                data-testid={`img-msg-${index}`}
                                                                onClick={() =>
                                                                    onOpenImageViewerClicked(index, imageList)
                                                                }
                                                                src={
                                                                    PREFIX_DISPLAY_DATA +
                                                                    getFileExtension(img.mimeType) +
                                                                    PREFIX_DISPLAY_BASE_64 +
                                                                    (img.thumbnail ? img.thumbnail : img.data)
                                                                }
                                                                alt=""
                                                                onContextMenu={blockContextMenu}
                                                            />
                                                        );
                                                    }
                                                })}
                                        </div>
                                    )}
                                </div>
                            )}

                            <div>
                                {visible && (
                                    <Dialog
                                        title={' '}
                                        onClose={() => onCloseImageViewerClicked()}
                                        className="image-modal">
                                        <ScrollView
                                            ref={scrollViewRef}
                                            style={{ width: 500, height: 500 }}
                                            endless={false}
                                            automaticViewChange={false}
                                            activeView={scrollViewImageIndex + 1}>
                                            {!viewImageList ? (
                                                <LoadingSpinner />
                                            ) : (
                                                viewImageList.map((img: Document, index: number) => {
                                                    return !img.data ? (
                                                        <LoadingSpinner key={'l' + index} />
                                                    ) : (
                                                        <img
                                                            key={img.id}
                                                            className="big-img-size"
                                                            src={PREFIX_DISPLAY_BASE_64_IMAGE + img.data}
                                                            alt=""
                                                            onContextMenu={blockContextMenu}
                                                        />
                                                    );
                                                })
                                            )}
                                        </ScrollView>
                                        <div className="download-icon-container">
                                            {' '}
                                            {!docsLoading && (
                                                <SvgIcon
                                                    className="download-icon-style"
                                                    icon={downloadIcon}
                                                    size="medium"
                                                    onClick={() => {
                                                        onDownloadImageClicked();
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </Dialog>
                                )}
                            </div>
                            <div className="chat-space" data-testid="chat-space">
                                <p className="c-messageContent">
                                    {/*Change the value from the true and the false below */}
                                    {!messageData.decrypted ? (
                                        <>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                aria-hidden="true"
                                                style={{ width: '30px', fill: 'white', padding: '1px' }}
                                                viewBox="0 0 512 512">
                                                {/* TODO : Changer le style inline par une classe pour avoir la bonne façon de faire*/}
                                                <path d="M256 480c123.7 0 224-100.3 224-224S379.7 32 256 32 32 132.3 32 256s100.3 224 224 224m-32-352h64v160h-64zm0 256v-64h64v64z" />
                                            </svg>
                                            &nbsp;
                                            <i>
                                                <>{t('errorAffichage', { ns: 'error' })}</>
                                            </i>
                                        </>
                                    ) : forwardedMessage ? (
                                        ''
                                    ) : (
                                        messageData.message
                                    )}
                                </p>
                                <div
                                    className="moreHorizontal-div"
                                    data-testid="moreHorizontal-div"
                                    onClick={handleContextMenu}>
                                    <SvgIcon className="moreHorizontal-item" icon={moreHorizontalIcon} />
                                </div>
                                <ContextMenu
                                    className="message-menu"
                                    vertical={true}
                                    onSelect={handleOnSelect}
                                    onClose={handleCloseMenu}
                                    show={show}
                                    offset={offset.current}>
                                    {PopupMenuItems}
                                </ContextMenu>
                            </div>
                        </div>
                    </div>

                    <div
                        className={`status-message ${isGroupConversation ? 'clickable' : ''}`}
                        id={isCurrentUserMessage ? '' : 'other-status-message'}
                        onClick={() => isGroupConversation && setVisibleDetailMessage(true)}>
                        {isCurrentUserMessage && !isGroupConversation
                            ? formatToMessageStatusAndDate(
                                  messageData.interlocutorReceivedDate,
                                  messageData.interlocutorReadDate,
                              )
                            : isGroupConversation &&
                              isCurrentUserMessage &&
                              `${t('readBy', { ns: 'message' })}
                              ${messageData.readCount - 1}
                              /${messageData.recipients.length - 1}`}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Message;
