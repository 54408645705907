import {
    fileCsvIcon,
    fileExcelIcon,
    filePdfIcon,
    fileWordIcon,
    filePptIcon,
    fileTxtIcon,
    fileIcon,
    SVGIcon,
} from '@progress/kendo-svg-icons';

export const iconMappings: Record<string, SVGIcon> = {
    pdf: filePdfIcon,
    doc: fileWordIcon,
    docx: fileWordIcon,
    xls: fileExcelIcon,
    xlsm: fileExcelIcon,
    xlsx: fileExcelIcon,
    csv: fileCsvIcon,
    ppt: filePptIcon,
    pptx: filePptIcon,
    rtf: fileTxtIcon,
};

export function getFileTypeIcon(documentExtension: string | undefined) {
    if (documentExtension) {
        const iconType: SVGIcon = iconMappings[documentExtension] || fileIcon;
        return iconType;
    }
}
