import { useContext, useEffect, useRef, useState } from 'react';
import { useQueries, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { createUsersApi, useAuth, eulaApi } from 'idside-core';
import { GlobalInfoContext } from '../../Contexts/globalInfoContext';
import LoadingSpinner from '../../component/LoadingSpinner/LoadingSpinner';
import './eula.css';
import { ViewDefault } from '../../common/ViewPath';
import GlobalSpinner from '../../component/GlobalSpinner';

// eslint-disable-next-line max-lines-per-function
export default function Eula() {
    const { orgID, accountId, setSecurityActive } = useContext(GlobalInfoContext);
    const [t] = useTranslation(['common', 'error']);
    const navigate = useNavigate();
    const eulaAgreementSectionRef = useRef<HTMLDivElement>(null);
    const eulaTextRef = useRef<HTMLDivElement>(null);
    const [isEulaRead, setIsEulaRead] = useState<boolean>(false);
    const { doLogout, authInstance } = useAuth();
    //TODO GET TEXT FROM ORGANISATION
    //Call back-end endpoint then feed it here
    //const [eulaText, setEulaText] = useState(string);
    const onCloseClick = () => {
        sessionStorage.removeItem('accountId');
        sessionStorage.removeItem('impersonatorName');
        sessionStorage.removeItem('orgID');
        sessionStorage.removeItem('secActive');
        setSecurityActive(false);
        doLogout({ redirectUri: window.location.origin });
    };

    const onCloseAccept = async () => {
        await createUsersApi(authInstance!, orgID || sessionStorage.getItem('orgID')!).addAttributes(orgID, accountId, {
            attributes: '{"accepted_eula":true}',
        });
        // localStorage.setItem('accepted_eula', 'true');
        setSecurityActive(true);
        sessionStorage.secActive = true;
        navigate(ViewDefault);
    };

    const {
        data: eulaMessage,
        isSuccess: eulaMessageSuccess,
        fetchStatus: eulaFetchStatus,
        status: eulaStatus,
    } = useQuery(['eulaMessage'], () =>
        eulaApi(orgID, authInstance)
            .getLastEula()
            .then((res) => {
                return res.data;
            }),
    );

    useEffect(() => {
        if (eulaAgreementSectionRef.current?.clientHeight! >= eulaAgreementSectionRef.current?.scrollHeight!) {
            setIsEulaRead(true);
        }
    }, [eulaAgreementSectionRef.current?.clientHeight, eulaAgreementSectionRef.current?.scrollHeight]);

    useEffect(() => {
        // Detect if div dont have scroll
        if (eulaMessageSuccess) {
            const handleEulaScroll = () => {
                if (eulaAgreementSectionRef.current) {
                    if (
                        Math.abs(
                            eulaAgreementSectionRef.current.scrollHeight -
                                (eulaAgreementSectionRef.current.scrollTop +
                                    eulaAgreementSectionRef.current.clientHeight),
                        ) <= 1
                    ) {
                        setIsEulaRead(true);
                    }
                }
            };
            const element = eulaAgreementSectionRef.current;
            element?.addEventListener('scroll', handleEulaScroll);

            return () => element?.removeEventListener('scroll', handleEulaScroll);
        }
    }, [eulaMessageSuccess]);

    return (
        <>
            {eulaMessageSuccess ? (
                <div className="eula-container">
                    <div
                        className="eula-agreement"
                        ref={eulaAgreementSectionRef}
                        data-testid="eula-agreement"
                        dangerouslySetInnerHTML={{ __html: eulaMessage.data! }}
                    />
                    <div>
                        <button
                            style={{ color: 'white', width: '30%', left: '18%', marginTop: '10px' }}
                            onClick={onCloseAccept}
                            disabled={!isEulaRead}
                            data-testid="button-accept-eula"
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">
                            {t('button.accept')}
                        </button>
                        <button
                            style={{ color: 'white', width: '30%', left: '22%', marginTop: '10px' }}
                            onClick={onCloseClick}
                            data-testid="button-close-eula"
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">
                            {t('button.cancel')}
                        </button>
                    </div>
                </div>
            ) : (
                <GlobalSpinner />
            )}{' '}
        </>
    );
}
