import { InterlocutorType } from './InterlocutorType';

export function getAvatarImagePath(userType: InterlocutorType | undefined, userIsDeleted: boolean | undefined): string {
    if (userType === undefined) {
        return getBlankAvatarImagePath();
    } else if (userIsDeleted === true && userType !== InterlocutorType.VirtualTeam) {
        //We have an image for basic users types that are deleted
        return getDeletedAvatarImagePath();
    } else {
        if (userType === InterlocutorType.GroupDiscussion) {
            return getGroupDiscussionAvatarImagePath();
        } else if (userType === InterlocutorType.VirtualTeam) {
            return getVirtualTeamAvatarImagePath();
        } else { // Doctor or User
            return getBasicUserAvatarImagePath();
        }
    }
}

function getBasicUserAvatarImagePath(): string {
    return '/images/avatar_user.svg';
}

function getVirtualTeamAvatarImagePath(): string {
    return '/images/avatar_team.svg';
}

function getGroupDiscussionAvatarImagePath(): string {
    return '/images/avatar_group.svg';
}

function getBlankAvatarImagePath(): string {
    return '/images/avatar_blank.svg';
}

function getDeletedAvatarImagePath(): string {
    return '/images/avatar_abs.svg';
}
