import { useTranslation } from 'react-i18next';

interface propsButtonClose {
    onClickClose: any
}

export default function ButtonClose(props: propsButtonClose) {
    const [t] = useTranslation();
    return (
        <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={props.onClickClose}>
            {t('button.close')}
        </button>
    );
}
