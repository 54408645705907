/* eslint-disable import/no-extraneous-dependencies*/
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { Label, Error } from '@progress/kendo-react-labels';
import { FieldWrapper } from '@progress/kendo-react-form';

interface ICreateGroupInput {
    hint: string;
    id: string;
    label: string;
    max: number;
    modified: any;
    name: string;
    onBlur: () => void;
    onChange: (e: any) => void;
    onFocus: () => void;
    onKeyDown: (e: any) => void;
    touched: any;
    valid: true;
    validationMessage: string;
    value: string;
    visited: any;
    disabled?: boolean;
    optional?: any;
    type?: any;
}

const CreateGroupInput = ({
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    max,
    value,
    ...others
}: ICreateGroupInput) => {
    const [t] = useTranslation();
    const refCreateName = useRef(null);

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hindId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';
    return (
        <div className="col-12">
            <FieldWrapper>
                <Label editorId={id} editorValid={valid} editorDisabled={disabled} optional={optional}>
                    {label}
                </Label>
                <div className={'k-form-field-wrap'}>
                    <Input
                        ref={refCreateName}
                        valid={valid}
                        type={type}
                        id={id}
                        disabled={disabled}
                        maxLength={max}
                        placeholder={t('groupName')}
                        value={value}
                        ariaDescribedBy={`${hindId} ${errorId}`}
                        {...others}
                    />
                    {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
                </div>
            </FieldWrapper>
        </div>
    );
};
export default CreateGroupInput;
