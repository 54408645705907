/* eslint-disable max-lines-per-function */
import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './messageDetails.css';
import {
    ISearchResultDetails,
    RecipientSummaryListUI,
    SearchUsageType,
    IStatusByUSer,
    getAvatarImagePath,
} from 'idside-ui';
import { formatToRelativeDate } from '../../common/messageInfoFormat';
import { ConversationContext } from '../../Contexts/conversationIdContext';
import { GlobalInfoContext } from '../../Contexts/globalInfoContext';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { AxiosResponse } from 'axios';
import {
    createMessagesApi,
    AuthService,
    createUsersApi,
    buildLogsAndPostToIndexedDB,
    settings,
    useAuth,
} from 'idside-core';
import UseUserAttributes from '../../hooks/useUserAttributes';
import { MimeTypeEnum } from '../commonData';
import { Buffer } from 'buffer';

type MessageDetailsProps = {
    messageData: IMessageDataItem;
};

interface IMessageDocument {
    data?: string;
    mimeType?: string;
    orderNumber?: number;
    referenceMessageId?: string;
}

interface IMessageDataItem {
    authorId: string;
    createdDate: string;
    id: string;
    documents?: IMessageDocument[];
    message: string;
    organisationId?: string;
    interlocutorReceivedDate?: string;
    interlocutorReadDate?: string;
    recipients: IRecipient[];
    user: string;
}

interface IRecipient {
    id: string;
    isAlertActive: boolean;
    key?: string;
}

interface IFowardMessageData {
    id: string;
    createdDate: string;
    documents?: IMessageDocument[];
    auteurId: string;
    impersonatorId: string;
    text: string;
    conversationId: string;
    auteur: string;
}

const MessageDetails = ({ messageData }: MessageDetailsProps) => {
    const { orgID: currentOrganisationId, setError, setErrorContent, currentUserId } = useContext(GlobalInfoContext);
    const { conversationId } = useContext(ConversationContext);
    const [t] = useTranslation(['common', 'message']);
    const [recipientsId, setRecipientsId] = useState<Array<ISearchResultDetails>>([]);
    const [statusByUser, setStatusByUser] = useState<Array<IStatusByUSer>>([]);
    const [forwardedMessage, setForwardedMessage] = useState<IFowardMessageData>();
    const auth = useAuth();
    const { authInstance } = auth;

    const onClose = () => {
        setError(false);
    };

    useEffect(() => {
        let recipientWOCurrentUser = messageData.recipients.filter((recipient) => recipient.id !== currentUserId);
        setRecipientsId(
            recipientWOCurrentUser.map((elt) => {
                const v: ISearchResultDetails = {
                    id: elt.id,
                    organisationIds: [],
                    isDeleted: undefined,
                    isActivated: undefined,
                    name: undefined,
                    type: undefined,
                    specialty: undefined,
                    place: undefined,
                    licenseNumber: undefined,
                };
                return v;
            }),
        );
    }, [messageData]);
    useEffect(() => {
        if (messageData && messageData.documents) {
            let copyOfDocuments = messageData.documents.slice();
            const base64MessageIndex = copyOfDocuments.findIndex(
                (e) => e.mimeType === MimeTypeEnum.FORWARD && e.referenceMessageId === undefined,
            );

            if (base64MessageIndex !== -1) {
                const base64Message = copyOfDocuments.splice(base64MessageIndex, 1)[0];
                setForwardedMessage(JSON.parse(Buffer.from(base64Message.data!, 'base64').toString()));
            }
        }
    }, [messageData]);

    useEffect(() => {
        const getStatusByUser = async () => {
            const start = new Date().getTime();
            if (AuthService.getToken(authInstance!)) {
                try {
                    const { data }: AxiosResponse = await createMessagesApi(authInstance!).getMessage(
                        currentOrganisationId,
                        messageData.id,
                        currentUserId,
                        false,
                        false,
                    );
                    setStatusByUser(data.recipients);
                } catch (err: any) {
                    await buildLogsAndPostToIndexedDB(
                        err.message,
                        err.stack,
                        new Date().getTime() - start,
                        settings.appId,
                        window.location.origin,
                        err.config.headers.trackingId,
                        err.config[`axios-retry`].retries,
                        authInstance?.sessionId!,
                        currentUserId,
                        err.config.headers.deviceId,
                        err.config.headers.organisationId,
                        err.config.headers.provider,
                        settings.dbName,
                        settings.storeIdTexto,
                    );
                    <ErrorMessage onClose={onClose} />;
                    setError(true);
                    setErrorContent(err);
                }
            }
        };
        getStatusByUser();
    }, [
        messageData.id,
        currentOrganisationId,
        currentUserId,
        authInstance?.sessionId,
        onClose,
        setError,
        setErrorContent,
        authInstance,
    ]);

    return (
        <div className="container-modal">
            <div className="detail-message">
                <div>
                    <strong>{t('by', { ns: 'message' })} :</strong> {messageData.user}
                </div>
                <div>{formatToRelativeDate(messageData.createdDate)}</div>
                {forwardedMessage &&
                forwardedMessage.text &&
                forwardedMessage.text.length > 0 &&
                messageData.message.length == 0 ? (
                    <div className="message">{forwardedMessage.text}</div>
                ) : (
                    <div className="message">{messageData.message}</div>
                )}
            </div>
            <hr />
            <div className="destinataires">
                <strong>{t('recipients', { ns: 'message' })}</strong>
            </div>
            <RecipientSummaryListUI
                filteredList={recipientsId}
                statusByUser={statusByUser}
                disableClick={true}
                authInstance={authInstance!}
                setError={setError}
                setErrorContent={setErrorContent}
                authService={AuthService}
                createUsersApi={createUsersApi}
                getAvatarImagePath={getAvatarImagePath}
                orgID={currentOrganisationId}
                useUserAttributes={UseUserAttributes}
                searchUsageType={SearchUsageType.UsageForMessageDetail}
                loadSpiner={true}
                authContext={auth}
            />
        </div>
    );
};

export default MessageDetails;
