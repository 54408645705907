/* eslint-disable max-lines-per-function */
import { useState, useContext, useEffect, ChangeEvent, useRef } from 'react';
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';
import '../Conversation/conversation.css';
import './messageInput.css';
import '../Message/message.css';
import { Dialog } from '@progress/kendo-react-dialogs';
import { ScrollView } from '@progress/kendo-react-scrollview';
import { ConversationContext } from '../../Contexts/conversationIdContext';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import {
    APPLICATION,
    IMAGE_MIME_TYPE,
    INTERNAL_DOCUMENT_MIME_TYPE,
    MessageContextMenuActionType,
    MessageContextMenuActionTypeEnum,
    PREFIX_DISPLAY_BASE_64_IMAGE,
    filterDocumentMimeTypes,
    filterImageMimeTypes,
    isAnImageMimeType,
} from '../commonData';
import { xIcon } from '@progress/kendo-svg-icons';
import { formatToRelativeDate } from '../../common/messageInfoFormat';
import { useAuth } from 'idside-core';
import { Document } from '../Message/Message';
import MessageDocument from '../MessageDocument/MessageDocument';
import { getExtensionsFromFile, isFileExtensionSupported } from '../../common/FileExtension';
import { GlobalInfoContext } from '../../Contexts/globalInfoContext';
import { useTranslation } from 'react-i18next';
import { ConvertSizeToMB } from '../../utils/fileDetails';
import { getMimeType } from '../FileDetails/FileDetails';
import { resizeFile } from '../../common/messageUtil';
import { blockContextMenu } from '../../common/imageElementUtil';
import { loadDocumentDataIfNeeded } from '../../common/DownloadFile';
import { loadCurrentImageIfNeeded, registerInoutHandlers } from '../../common/scrollViewUtil';
import { v4 as uuidv4 } from 'uuid';

interface IRecipient {
    id: string;
    isAlertActive?: boolean;
    key?: string;
}

export type InputProps = {
    sendMessage: () => void;
    Paste: () => void;
    isShown: boolean;
    imageList: any;
    setImageList: (arg: any) => void;
    setCurrentImage: any;
    currentImage: string;
    isPasteInput: any;
    currentMessage: string;
    setCurrentMessage: (e: string) => void;
    showCount: boolean;
    isShowCount: (arg: boolean) => void;
    count: number;
    newRecipientArray: IRecipient[];
    getConversationName: string;
    getUserNameArray: any;
    setCount: (e: HTMLElement) => void;
    isDisabled: boolean;
    textOnlyInput: boolean;
    loading: boolean;
    replyTo: string | undefined;
    dateOfMessageBeingRepliedTo: string | undefined;
    messageTextBeingRepliedTo: string | undefined;
    actionType: MessageContextMenuActionType;
    DisplayResponseMessageBox: () => void;
    isDisplayResponseMessageBox: boolean;
    simpleMessageDocumentList: Document[];
    setSimpleMessageDocumentList: (arg: Document[]) => void;
};
const MessageInput = ({
    sendMessage,
    Paste,
    isShown,
    imageList: replyDocumentList,
    setImageList,
    currentMessage,
    setCurrentMessage,
    showCount,
    isShowCount,
    count,
    setCount,
    isDisabled,
    textOnlyInput = false,
    loading,
    replyTo,
    dateOfMessageBeingRepliedTo,
    messageTextBeingRepliedTo,
    actionType,
    DisplayResponseMessageBox,
    isDisplayResponseMessageBox,
    simpleMessageDocumentList: documentList,
    setSimpleMessageDocumentList,
}: InputProps) => {
    const { conversationId } = useContext(ConversationContext);
    const [visible, setVisible] = useState<boolean>(false);
    const [imageTextContainerStyle, setImageTextContainerStyle] = useState<string>('');
    const [inputBarStyle, setInputBarStyle] = useState<string>('input-bar');
    const [scrollViewImageIndex, setScrollViewImageIndex] = useState<number>(0);
    const [fileKey, setFileKey] = useState<number>(0);
    const [orderNumberValue, setOrderNumberValue] = useState<number>(1);
    const { attachmentConfigurations, orgID, currentUserId, setError, setSpecificErrorMessage } =
        useContext(GlobalInfoContext);
    const [t] = useTranslation(['error']);
    const { authInstance } = useAuth();
    const scrollViewRef = useRef<any>(null);
    const [carousselList, setCarousselList] = useState<Document[]>([]);
    const [replyDocumentListInput, setReplyDocumentListInput] = useState<Document[]>([]);
    const [replyImageListInput, setReplyImageListInput] = useState<Document[]>([]);
    const [documentListInput, setDocumentListInput] = useState<Document[]>([]);
    const [imageListInput, setImageListInput] = useState<Document[]>([]);

    useEffect(() => {
        setReplyImageListInput(filterImageMimeTypes(replyDocumentList));
        setReplyDocumentListInput(filterDocumentMimeTypes(replyDocumentList));
    }, [replyDocumentList]);

    useEffect(() => {
        setImageListInput(filterImageMimeTypes(documentList));
        setDocumentListInput(filterDocumentMimeTypes(documentList));
    }, [documentList]);

    const handleChangeText = (e: any) => {
        setCount(e.target.value.length);
        if (e.target.value.length >= 400) {
            isShowCount(true);
        } else {
            isShowCount(false);
        }
    };
    // TODO: Fix when we do a copy/paste, then do a reply, then cancel reply and we still have copied images
    // useEffect(() => {
    //     if (actionType === MessageContextMenuActionTypeEnum.Reply) {
    //         setSimpleMessageDocumentList([]);
    //     }
    // }, [actionType]);

    const deleteImgInput = (id: string | undefined) => {
        if (id && documentList?.length > 0) {
            const documentIndex = documentList.findIndex((doc: Document) => doc.id === id);

            if (documentIndex >= 0) {
                documentList.splice(documentIndex, 1);

                setSimpleMessageDocumentList([...documentList]);
                setFileKey(fileKey + 1);
            }
        }
    };

    useEffect(() => {
        if (documentList?.length > 1) {
            sortArrayByOrderNumber(documentList);
        }
        setFileKey(fileKey + 1);
    }, [documentList?.length]);

    useEffect(() => {
        const input = document.querySelector('.chat-feed');
        input?.classList.add('chat-feed-count');
        if (showCount === false) {
            input?.classList.remove('chat-feed-count');
        }
    }, [showCount]);

    function sortArrayByOrderNumber(arr: Document[]) {
        arr.sort((a: Document, b: Document) => {
            const orderA = a?.orderNumber ?? 0;
            const orderB = b?.orderNumber ?? 0;
            return orderA - orderB;
        });

        return arr;
    }

    useEffect(() => {
        setImageTextContainerStyle('');
        setInputBarStyle('input-bar');

        if (textOnlyInput === false && actionType === MessageContextMenuActionTypeEnum.Copy) {
            setImageTextContainerStyle('image-text-container');
            setInputBarStyle('input-bar-image');
        }

        if (actionType === MessageContextMenuActionTypeEnum.Reply) {
            setImageTextContainerStyle('image-text-container');
            setInputBarStyle('input-bar-image');
        }
        if (!isDisplayResponseMessageBox && actionType === MessageContextMenuActionTypeEnum.Reply) {
            setImageTextContainerStyle('image-text-container');
            setInputBarStyle('input-bar-image');
        }
        if (documentList?.length > 0) {
            setImageTextContainerStyle('image-text-container');
            setInputBarStyle('input-bar-image');
        }
    }, [sendMessage, textOnlyInput, actionType, isDisplayResponseMessageBox]);

    function onAddFileClicked(): void {
        const fileInput = document.getElementById('fileInput');
        if (fileInput && !isDisabled) {
            fileInput.click();
        }
    }

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        const fileExtension = getExtensionsFromFile(file);

        if (file && fileExtension) {
            const fileSizeMB = ConvertSizeToMB(file.size);
            const totalSizePostDocumentList = documentList.reduce((accumulator: number, currentValue: any) => {
                return accumulator + currentValue.size;
            }, 0);

            if (!isFileExtensionSupported(fileExtension, attachmentConfigurations?.supportedFileTypes?.split(',')!)) {
                setError(true);
                setSpecificErrorMessage(t('nonSupportedDocument'));
                return;
            }

            if (fileSizeMB > attachmentConfigurations?.attachmentMaxSize!) {
                setError(true);
                setSpecificErrorMessage(
                    t('selectedDocumentFileSizeExceed', { size: attachmentConfigurations?.attachmentMaxSize }),
                );
                return;
            }
            if (totalSizePostDocumentList + fileSizeMB > attachmentConfigurations?.messageDataMaxSize!) {
                setError(true);
                setSpecificErrorMessage(t('fileSizeExceed', { size: attachmentConfigurations?.messageDataMaxSize }));
                return;
            }

            if (documentList.length + 1 > attachmentConfigurations?.docMaxByMessage!) {
                setError(true);
                setSpecificErrorMessage(
                    t('docMaxByMessage', { fileNumber: attachmentConfigurations?.docMaxByMessage }),
                );
                return;
            }

            const fileName = file.name;
            const reader = new FileReader();
            const fileMimeType = getMimeType(file?.name);

            let fileData;
            reader.onload = async () => {
                const base64Data = reader.result as string;

                // Define a regular expression to match the part between "data:" and ";base64"
                const extractMimeTypeRegex = /data:(.*?)\/(.*?);base64,/;

                // Remove the matched part
                fileData = base64Data.replace(extractMimeTypeRegex, ``);

                let newOrderNumber = orderNumberValue + 1;
                setOrderNumberValue(newOrderNumber);

                const isImageDocumentType = file.type.startsWith(IMAGE_MIME_TYPE);

                let b64ThumbnailWithoutHeader: string = '';

                if (isImageDocumentType) {
                    const resizedImage = await resizeFile(file);
                    b64ThumbnailWithoutHeader = resizedImage.replace(extractMimeTypeRegex, ``);
                }

                setSimpleMessageDocumentList([
                    ...documentList,
                    {
                        id: 'temp-' + uuidv4(), //CNS-6841: Set a temp id to make the file unique and enable React to update correctly if we remove/update files. Note the actual id will be created by the web service and updated locally when send is successful.
                        orderNumber: newOrderNumber,
                        mimeType: fileMimeType,
                        data: fileData,
                        filename: fileName,
                        thumbnail: isImageDocumentType ? b64ThumbnailWithoutHeader : undefined,
                        size: fileSizeMB,
                    },
                ]);
            };

            reader.readAsDataURL(file);
            setImageTextContainerStyle('image-text-container');
            setInputBarStyle('input-bar-image');
        } else {
            setError(true);
            setSpecificErrorMessage(t('nonSupportedDocument'));
            return;
        }
    };

    // TODO: Replace all instance of (arg: Document[]) => void  with: Dispatch<SetStateAction<T>>)
    async function onOpenImageViewerClicked(index: number, documents: Document[]) {
        setCarousselList(documents);
        setScrollViewImageIndex(index);
        setVisible(true);

        await loadDocumentDataIfNeeded(documents, index, orgID, currentUserId, authInstance);
    }

    function onScrollViewUserInput() {
        loadCurrentImageIfNeeded(
            scrollViewRef,
            carousselList,
            setCarousselList,
            loadDocumentDataIfNeeded,
            orgID,
            currentUserId,
            authInstance,
        );
    }

    useEffect(() => {
        registerInoutHandlers(scrollViewRef, onScrollViewUserInput);
    }, [scrollViewRef.current?.element]);

    return (
        <>
            <div className={imageTextContainerStyle}>
                {conversationId && (
                    <>
                        {isDisplayResponseMessageBox && actionType === MessageContextMenuActionTypeEnum.Reply && (
                            <div
                                data-testid="message-text-being-replied-to-box"
                                className="message-text-being-replied-to-box displyRow">
                                <div className="displayColumn silverLine"></div>

                                <div className="displayColumn">
                                    <div className="displyRow user-name-date-close-bt">
                                        <div className="displayColumn message-user-name-date">
                                            <span className="message-to-reply-to-username">{replyTo}</span>
                                            <span className="message-to-reply-to-date">
                                                {formatToRelativeDate(dateOfMessageBeingRepliedTo, false, false)}
                                            </span>
                                        </div>
                                        <div className="displayColumn close-reply">
                                            <button
                                                className="close-reply-icon"
                                                onClick={() => DisplayResponseMessageBox()}>
                                                <SvgIcon icon={xIcon} size="medium" />
                                            </button>
                                        </div>
                                    </div>

                                    <div className="message-text-image-to-reply-to">
                                        <div className="temp-img">
                                            {replyDocumentListInput.length > 0 &&
                                                replyDocumentListInput.map((doc: Document, index: number) => {
                                                    return (
                                                        <MessageDocument
                                                            key={doc.id}
                                                            doc={doc}
                                                            isCurrentUserMessage={true}
                                                            isInputField={true}
                                                            isDownloadAvailable={false}
                                                        />
                                                    );
                                                })}
                                            {replyImageListInput.length > 0 &&
                                                replyImageListInput.map((img: Document, index: number) => {
                                                    return (
                                                        <img
                                                            key={img.id}
                                                            className="c-input-img"
                                                            data-testid="c-input-img"
                                                            src={
                                                                PREFIX_DISPLAY_BASE_64_IMAGE +
                                                                (img.thumbnail ? img.thumbnail : img.data)
                                                            }
                                                            onClick={() => onOpenImageViewerClicked(index, replyImageListInput)}
                                                            alt=""
                                                            onContextMenu={blockContextMenu}
                                                        />
                                                    );
                                                })}
                                        </div>
                                        <div className="message-text-being-replied-to">{messageTextBeingRepliedTo}</div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {actionType !== MessageContextMenuActionTypeEnum.Forward && (
                            <div className="temp-img">
                                {documentListInput.length > 0 &&
                                    documentListInput.map((doc: Document, index: number) => {
                                        return (
                                            <div key={doc.id} style={{ display: 'flex' }}>
                                                <MessageDocument
                                                    key={doc.id}
                                                    doc={doc}
                                                    isCurrentUserMessage={true}
                                                    isInputField={true}
                                                    isDownloadAvailable={false}
                                                />
                                                <SvgIcon
                                                    onClick={() => deleteImgInput(doc.id)}
                                                    className="close-doc"
                                                    themeColor="primary"
                                                    icon={svgIcons.xCircleIcon}
                                                />
                                            </div>
                                        );
                                    })}
                                {imageListInput.length > 0 &&
                                    imageListInput.map((img: Document, index: number) => {
                                        return (
                                            <div key={img.id} className="x-circle-Icone">
                                                <div className="container-close-img">
                                                    <SvgIcon
                                                        onClick={() => {
                                                            deleteImgInput(img.id);
                                                        }}
                                                        className="close-img"
                                                        themeColor="primary"
                                                        icon={svgIcons.xCircleIcon}
                                                    />
                                                </div>

                                                <img
                                                    className="c-input-img"
                                                    data-testid="c-input-img"
                                                    src={
                                                        PREFIX_DISPLAY_BASE_64_IMAGE +
                                                        (img.thumbnail ? img.thumbnail : img.data)
                                                    }
                                                    onClick={() => onOpenImageViewerClicked(index, imageListInput)}
                                                    alt=""
                                                    onContextMenu={blockContextMenu}
                                                />
                                            </div>
                                        );
                                    })}
                            </div>
                        )}
                        {showCount && <div className="text-count">{count}/500</div>}
                        <div className="c-input-container">
                            <div>
                                {visible && (
                                    <Dialog title={' '} onClose={() => setVisible(false)} className="image-modal">
                                        <ScrollView
                                            ref={scrollViewRef}
                                            style={{ width: 500, height: 384 }}
                                            endless={false}
                                            activeView={scrollViewImageIndex + 1}
                                            automaticViewChange={false}>
                                            {carousselList ? (
                                                carousselList?.map((imageDoc: Document) => {
                                                    return !imageDoc.data ? (
                                                        <LoadingSpinner key={'l' + imageDoc.id} />
                                                    ) : (
                                                        <img
                                                            key={imageDoc.id}
                                                            className="input-big-img"
                                                            style={{ width: 'auto', height: 384 }}
                                                            src={PREFIX_DISPLAY_BASE_64_IMAGE + imageDoc.data}
                                                            alt=""
                                                            onContextMenu={blockContextMenu}
                                                        />
                                                    );
                                                })
                                            ) : (
                                                <LoadingSpinner />
                                            )}
                                        </ScrollView>
                                    </Dialog>
                                )}
                            </div>
                            <div className="input-small-container">
                                <div className={`bigger-input ${inputBarStyle}`}>
                                    <SvgIcon
                                        icon={svgIcons.plusIcon}
                                        onClick={() => {
                                            onAddFileClicked();
                                        }}
                                    />
                                    <input
                                        type="file"
                                        id="fileInput"
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                        data-testid="upload-file"
                                        key={fileKey}
                                    />
                                    <input
                                        type="text"
                                        className="small-input"
                                        maxLength={500}
                                        value={currentMessage ? currentMessage : ''}
                                        onChange={(event) => {
                                            setCurrentMessage(event.target.value);
                                            handleChangeText(event);
                                        }}
                                        onKeyPress={async (event) => {
                                            if (event.key === 'Enter') {
                                                if (!isDisabled) {
                                                    await sendMessage();
                                                }
                                            }
                                        }}
                                        disabled={isDisabled}
                                        accept="image/*"
                                        //TODO translation
                                        placeholder="Écrire un message..."
                                    />
                                </div>
                                <div className="paste-message" data-testid="paste-message" onClick={() => Paste()}>
                                    <SvgIcon
                                        className={isShown ? 'dc-clipboard' : 'hide'}
                                        icon={svgIcons.clipboardIcon}
                                    />
                                </div>

                                {isDisabled || loading ? (
                                    <div className="loader-container">
                                        <LoadingSpinner />
                                    </div>
                                ) : (
                                    //Only shown if enabled and not loading
                                    <button
                                        data-testid="send-message-button"
                                        className="c-send"
                                        onClick={async () => {
                                            await sendMessage();
                                        }}>
                                        {/* TODO : Changer svg */}
                                        <span className="k-button-text">
                                            <span className="k-button-icon k-icon">
                                                <svg
                                                    version="1.1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 16 16">
                                                    <path d="M0,14.3c-0.1,0.6,0.3,0.8,0.8,0.6l14.8-6.5c0.5-0.2,0.5-0.6,0-0.8L0.8,1.1C0.3,0.9-0.1,1.1,0,1.7l0.7,4.2C0.8,6.5,1.4,7,1.9,7.1l8.8,0.8c0.6,0.1,0.6,0.1,0,0.2L1.9,8.9C1.4,9,0.8,9.5,0.7,10.1L0,14.3z"></path>
                                                </svg>
                                            </span>
                                        </span>
                                    </button>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default MessageInput;
