import { mimeToExtension } from '../component/commonData';

function getFileExtension(filename: string) {
    const lowercaseFilename = filename.toLowerCase();
    const parts = lowercaseFilename.split('.');
    return parts[parts.length - 1];
}

export function getExtensionsFromFile(file: File | undefined): string[] | undefined {
    if (file?.name) {
        const lastDotIndex = file.name.lastIndexOf('.');
        if (lastDotIndex !== -1) {
            return [file.name.slice(lastDotIndex + 1)];
        }
    }

    if (file?.type) {
        return mimeToExtension[file.type] || '';
    }

    return undefined;
}

export function isFileExtensionSupported(fileExtension: string[], supportedFileTypes: string[]): boolean {
    const lowerCaseFileExtension = fileExtension.map((ext) => ext.toLowerCase());
    return lowerCaseFileExtension.every((ext) => supportedFileTypes.includes(ext));
}

export default getFileExtension;
