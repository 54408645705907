import UnavailableAvatar from '../UnavailableAvatar/UnavailableAvatar';
import '../MessageInput/messageInput.css';
import { useTranslation } from 'react-i18next';
import { getAvatarImagePath } from '../../common/avatarPicture';
import { UserTypeEnum } from 'idside-core';

interface IUnavailableInput {
    userName: string | undefined;
    availabilityDate: string | undefined;
    isDeleted: boolean | undefined;
}

const UnavailableInput = ({ userName, availabilityDate, isDeleted }: IUnavailableInput) => {
    const [t] = useTranslation('common');

    return (
        <div className="non-dispo-input" data-testid="non-dispo-input">
            <div className={isDeleted ? 'avatar-container-non-available-deleted' : 'avatar-container-non-available'}>
                <div className="lock-container">{isDeleted ? '' : <UnavailableAvatar />}</div>
                <img src={getAvatarImagePath(UserTypeEnum.Other, isDeleted)} className="cg-chat-perso-input" alt="" />
            </div>
            {availabilityDate ? (
                <div className="cg-top-info-input-non-available" data-testid="info-input-non-available">
                    <div className="cg-nom-input">{userName}</div>
                    <div className="cg-temps-input">
                        {' '}
                        {t('availability')} {availabilityDate}
                    </div>
                </div>
            ) : (
                <div className="cg-top-info-input-non-available">
                    <div className="cg-nom-input">{userName}</div>
                    <div className="cg-temps-input">
                        <i data-testid="deleted-status-text-input">{t('userDeleted')}</i>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UnavailableInput;
