import { Document } from './Message/Message';

export const MessageContextMenuActionTypeEnum = {
    Copy: 'COPY',
    Reply: 'REPLY',
    Forward: 'FORWARD',
    NoAction: 'EMPTY',
};

export type MessageActionType =
    (typeof MessageContextMenuActionTypeEnum)[keyof typeof MessageContextMenuActionTypeEnum];

export type MessageContextMenuActionType =
    (typeof MessageContextMenuActionTypeEnum)[keyof typeof MessageContextMenuActionTypeEnum];

export const PREFIX_DISPLAY_BASE_64_IMAGE = 'data:image/jpg;base64,';
export const PREFIX_DISPLAY_DATA_IMAGE = 'data:image/';
export const PREFIX_DISPLAY_DATA = 'data:';
export const PREFIX_DISPLAY_BASE_64 = ';base64,';
export const IMAGE_NAME_DOWNLOAD = 'image';
export const IMAGE_MIME_TYPE = 'image/';
export const APPLICATION = 'application/';
export const TEXT = 'text/';
export const REGEX_EXTRACT_PREFIX = '/^data:application/w+;base64,/';
export const INTERNAL_DOCUMENT_MIME_TYPE = 'app/';

export const DocumentExtensionEnum: { [extension: string]: string } = {
    pdf: 'application/pdf',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    png: 'image/png',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xlsm: 'application/vnd.ms-excel.sheet.macroEnabled.12',
    csv: 'text/csv',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    rtf: 'application/rtf',
};

export const MimeTypeEnum = {
    FORWARD: INTERNAL_DOCUMENT_MIME_TYPE + 'transfer',
    REPLY: INTERNAL_DOCUMENT_MIME_TYPE + 'reply',
};

export interface ImageMimeTypeEnum {
    [mimeType: string]: string[];
}
export const mimeToExtension: ImageMimeTypeEnum = {
    // Added to support existing jpg images
    'image/jpg': ['jpeg'],
    'image/jpeg': ['jpeg', 'jpg'],
    'image/png': ['png'],
    'audio/aac': ['aac'],
    'application/x-abiword': ['abw'],
    'application/octet-stream': ['arc', 'bin', 'bz', 'bz2'],
    'video/x-msvideo': ['avi'],
    'application/vnd.amazon.ebook': ['azw'],
    'image/bmp': ['bmp'],
    'application/x-bzip': ['bz'],
    'application/x-bzip2': ['bz2'],
    'application/x-csh': ['csh'],
    'text/css': ['css'],
    'text/csv': ['csv'],
    'application/msword': ['doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['docx'],
    'application/vnd.ms-fontobject': ['eot'],
    'application/epub+zip': ['epub'],
    'image/gif': ['gif'],
    'text/html': ['htm', 'html'],
    'image/x-icon': ['ico'],
    'text/calendar': ['ics'],
    'application/java-archive': ['jar'],
    'application/javascript': ['js'],
    'application/json': ['json'],
    'audio/midi': ['mid', 'midi'],
    'video/mpeg': ['mpeg'],
    'application/vnd.apple.installer+xml': ['mpkg'],
    'application/vnd.oasis.opendocument.presentation': ['odp'],
    'application/vnd.oasis.opendocument.spreadsheet': ['ods'],
    'application/vnd.oasis.opendocument.text': ['odt'],
    'audio/ogg': ['oga', 'ogg'],
    'video/ogg': ['ogv'],
    'application/ogg': ['ogx'],
    'font/otf': ['otf'],
    'application/pdf': ['pdf'],
    'application/vnd.ms-powerpoint': ['ppt'],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['pptx'],
    'application/x-rar-compressed': ['rar'],
    'application/rtf': ['rtf'],
    'application/x-sh': ['sh'],
    'image/svg+xml': ['svg'],
    'application/x-shockwave-flash': ['swf'],
    'application/x-tar': ['tar'],
    'image/tiff': ['tif', 'tiff'],
    'application/typescript': ['ts'],
    'font/ttf': ['ttf'],
    'application/vnd.visio': ['vsd'],
    'audio/x-wav': ['wav'],
    'audio/webm': ['weba'],
    'video/webm': ['webm'],
    'image/webp': ['webp'],
    'font/woff': ['woff'],
    'font/woff2': ['woff2'],
    'application/xhtml+xml': ['xhtml'],
    'application/vnd.mozilla.xul+xml': ['xul'],
    'application/zip': ['zip'],
    'video/3gpp': ['3gp', '3g2'],
    'audio/3gpp': ['3gp', '3g2'],
    'application/x-7z-compressed': ['7z'],
};

export const DataForContextMenuAction = [
    {
        text: 'copy',
        icon: 'copy',
        action: MessageContextMenuActionTypeEnum.Copy,
        class: '',
    },
    {
        text: 'reply',
        icon: 'undo',
        action: MessageContextMenuActionTypeEnum.Reply,
        class: 'rotate-reply-icon',
    },
    {
        text: 'forward',
        icon: 'redo',
        action: MessageContextMenuActionTypeEnum.Forward,
        class: '',
    },
];

export function isAnInternalDocumentMimeType(mimeType: string) {
    return mimeType.startsWith(INTERNAL_DOCUMENT_MIME_TYPE);
}

export function isAnImageMimeType(mimeType: string) {
    return mimeType.startsWith(IMAGE_MIME_TYPE);
}
export const filterImageMimeTypes = (files: Document[]): Document[] => {
    return files.filter((file) => file.mimeType.startsWith(IMAGE_MIME_TYPE));
};

export const filterDocumentMimeTypes = (files: Document[]): Document[] => { 
    return files.filter((file) => file.mimeType.startsWith(APPLICATION) || file.mimeType.startsWith(TEXT));
};
