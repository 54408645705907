/* eslint-disable max-lines-per-function */
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './chat.css';
import Conversation from '../../component/Conversation/Conversation';
import ConversationSelectorList from '../../component/ConversationSelectorList/ConversationSelectorList';
import { enableBrowserNotification, hasSilentNotification } from '../../common/notificationsUtil';
import { GlobalInfoContext } from '../../Contexts/globalInfoContext';
import {
    createConversationsApi,
    ApiMsConversations,
    buildLogsAndPostToIndexedDB,
    settings,
    useAuth,
} from 'idside-core';

const Chat = () => {
    const [conversations, setConversations] = useState<ApiMsConversations.ConversationResponse[]>([]);
    const [loading, setLoading] = useState(true);
    const [previousCurrentUserId, setPreviousCurrentUserId] = useState<string | undefined>();
    const {
        orgID,
        setError,
        setErrorContent,
        currentUserId,
        firebaseNotifications,
        isConversationActive,
        setIsConversationActive,
    } = useContext(GlobalInfoContext);
    const { conversationIdParam } = useParams<string>();
    const { getToken, authInstance } = useAuth();

    useEffect(() => {
        enableBrowserNotification();
    }, []);

    /*TODO : Verifier si c'est encore nécessaire avec le contextConversation dans App
    * Sinon supprimer les lignes de codes commentarisées en lien avec la selection 
    const onSelectionChanged = (selectedConversationId: string): void => {
        setConversationID(selectedConversationId);
    };*/

    const retrieveConversations = async () => {
        const start = new Date().getTime();
        if (getToken() && currentUserId && orgID) {
            try {
                if (currentUserId !== previousCurrentUserId) {
                    setPreviousCurrentUserId(currentUserId);

                    setConversations([]);
                    setLoading(true);
                }
                const { data } = await createConversationsApi(authInstance!).getConversationsByRecipientId(
                    orgID,
                    currentUserId,
                    true,
                );
                const conversationsWithMessagesReaded = data.map((conversation) => ({
                    ...conversation,
                    allMessagesReaded: false,
                }));
                setConversations(conversationsWithMessagesReaded);
                setLoading(false);
            } catch (err: any) {
                await buildLogsAndPostToIndexedDB(
                    err.message,
                    err.stack,
                    new Date().getTime() - start,
                    settings.appId,
                    window.location.origin,
                    err.config.headers.trackingId,
                    err.config[`axios-retry`].retries,
                    authInstance?.sessionId!,
                    currentUserId,
                    err.config.headers.deviceId,
                    err.config.headers.organisationId,
                    err.config.headers.provider,
                    settings.dbName,
                    settings.storeIdTexto,
                );
                console.log('Error obtaining conversation "', currentUserId, '" : ', err);
                setLoading(false);
                setError(true);
                setErrorContent(err);
            }
        }
    };

    useEffect(() => {
        retrieveConversations();
    }, [currentUserId, setError, setErrorContent, orgID]);

    useEffect(() => {
        //TODO: Optimize to update only for silent message with newMessage
        if (hasSilentNotification(firebaseNotifications)) {
            retrieveConversations();
        }
    }, [firebaseNotifications]);

    useEffect(() => {
        retrieveConversations();
        updateActiveConversation(conversationIdParam!);
    }, [conversationIdParam]);

    const updateActiveConversation = (id: string): void => {
        if (isConversationActive === id) return;
        setIsConversationActive(isConversationActive !== id ? id : -1);
    };

    return (
        <>
            <div className="conv-container">
                <div className="chat-container">
                    <div className="sidebar">
                        <ConversationSelectorList
                            conversations={conversations}
                            setConversations={setConversations}
                            loading={loading}
                            onSearchSelected={conversationIdParam}
                            updateActiveConversation={updateActiveConversation}
                            setLoading={setLoading}
                        />
                    </div>
                    <div className="chat">
                        <Conversation
                            conversations={conversations}
                            setConversations={setConversations}
                            conversationIdParam={conversationIdParam}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Chat;
