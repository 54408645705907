import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useTranslation } from 'react-i18next';
import './errorMessage.css';
import { GlobalInfoContext } from '../../Contexts/globalInfoContext';
import { useContext } from 'react';

type ErrorMessageProps = {
    onClose: () => void;
};

export default function ErrorMessage({ onClose }: ErrorMessageProps) {
    const [t] = useTranslation(['common', 'error']);
    const { specificErrorMessage, setSpecificErrorMessage } = useContext(GlobalInfoContext);

    const onCloseClick = () => {
        setSpecificErrorMessage(undefined);
        onClose();
    };

    return (
        <Dialog className="error-popup" title={t('problem', { ns: 'error' })} onClose={onCloseClick} id="error-dialog">
            <p className="error-message" data-testid="error-content">
                {specificErrorMessage ?? t('errorConnexion', { ns: 'error' })}
            </p>
            <DialogActionsBar>
                <button
                    style={{ color: 'white', width: '100%' }}
                    onClick={onCloseClick}
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">
                    {t('button.ok')}
                </button>
            </DialogActionsBar>
        </Dialog>
    );
}
