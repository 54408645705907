/* eslint-disable max-lines-per-function */
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalInfoContext } from '../../Contexts/globalInfoContext';
import './createConversation.css';
import { AxiosResponse } from 'axios';
import { buildLogsAndPostToIndexedDB, createConversationsApi, settings, useAuth } from 'idside-core';
import { SearchUIContainer, SearchUsageType } from 'idside-ui';
import UseUserAttributes from '../../hooks/useUserAttributes';

const CreateConversation = () => {
    const {
        orgID: currentOrganisationId,
        currentUserId,
        accountId,
        setError,
        setErrorContent,
    } = useContext(GlobalInfoContext);
    const navigate = useNavigate();
    const auth = useAuth();
    const { getToken, authInstance } = auth;

    const createOrLoadConversation = async (userId: string) => {
        const startCreateOrLoadConv = new Date().getTime();
        if (getToken())
            try {
                const { data }: AxiosResponse = await createConversationsApi(
                    authInstance!,
                ).getConversationsByRecipientId(currentOrganisationId, currentUserId);
                const conversationFound: any = data.find((conversation: any) => {
                    return (
                        conversation.name == null &&
                        conversation.recipients.find((recipient: any) => {
                            return recipient.id === userId;
                        })
                    );
                });
                const showConversation = async (conversationId: string) => {
                    const startShowConv = new Date().getTime();
                    try {
                        await createConversationsApi(authInstance!, currentOrganisationId).editConversationV2(
                            conversationId,
                            {
                                editedRecipients: [
                                    {
                                        id: currentUserId,
                                        masked: false,
                                    },
                                ],
                            },
                            {
                                headers: {
                                    currentUserId: currentUserId,
                                },
                            },
                        );
                    } catch (err: any) {
                        await buildLogsAndPostToIndexedDB(
                            err.message,
                            err.stack,
                            new Date().getTime() - startShowConv,
                            settings.appId,
                            window.location.origin,
                            err.config.headers.trackingId,
                            err.config[`axios-retry`].retries,
                            authInstance?.sessionId!,
                            currentUserId,
                            err.config.headers.deviceId,
                            err.config.headers.organisationId,
                            err.config.headers.provider,
                            settings.dbName,
                            settings.storeIdTexto,
                        );
                        console.log(err);
                    }
                };
                if (conversationFound !== undefined) {
                    await showConversation(conversationFound.id);
                    navigate(`/chat/${currentOrganisationId}/${conversationFound.id}`);
                } else {
                    if (getToken()) {
                        const startCreateConv = new Date().getTime();
                        try {
                            const { data: postConversationData } = await createConversationsApi(
                                authInstance!,
                            ).postConversation(currentOrganisationId, {
                                creatorId: currentUserId,
                                //ligne 64 vérifier si le recipients est le bon et non accountId pareil pour ligne 44
                                recipients: [{ id: currentUserId }, { id: userId }],
                            });
                            navigate(`/chat/${currentOrganisationId}/${postConversationData.id}`);
                        } catch (err: any) {
                            await buildLogsAndPostToIndexedDB(
                                err.message,
                                err.stack,
                                new Date().getTime() - startCreateConv,
                                settings.appId,
                                window.location.origin,
                                err.config.headers.trackingId,
                                err.config[`axios-retry`].retries,
                                authInstance?.sessionId!,
                                currentUserId,
                                err.config.headers.deviceId,
                                err.config.headers.organisationId,
                                err.config.headers.provider,
                                settings.dbName,
                                settings.storeIdTexto,
                            );
                            console.log(`Error creating conversation with recipient ${userId}`, err);
                        }
                    }
                }
            } catch (err: any) {
                await buildLogsAndPostToIndexedDB(
                    err.message,
                    err.stack,
                    new Date().getTime() - startCreateOrLoadConv,
                    settings.appId,
                    window.location.origin,
                    err.config.headers.trackingId,
                    err.config[`axios-retry`].retries,
                    authInstance?.sessionId!,
                    currentUserId,
                    err.config.headers.deviceId,
                    err.config.headers.organisationId,
                    err.config.headers.provider,
                    settings.dbName,
                    settings.storeIdTexto,
                );
                console.log('Error ConversationsSelector', err);
                setError(true);
                setErrorContent(err);
            }
    };

    return (
        <>
            <div>
                <SearchUIContainer
                    funcForSelectedUserId={createOrLoadConversation}
                    filteredList={[]}
                    currentOrganisationId={currentOrganisationId}
                    setError={setError}
                    setErrorContent={setErrorContent}
                    currentUserId={currentUserId}
                    authInstance={authInstance!}
                    useUserAttributes={UseUserAttributes}
                    searchUsageType={SearchUsageType.UsageForCreateConversation}
                    authContext={auth}
                />
            </div>
        </>
    );
};

export default CreateConversation;
