import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import ButtonClose from './ButtonClose';
import ButtonYesNo from './ButtonYesNo';

interface propsNotificationMessage {
    onClose: () => void;
    title: string;
    textContent: string;
    onConfirmYes?: any;
}

const NotificationMessage = ({ onClose, title, textContent, onConfirmYes }: propsNotificationMessage) => {
    const handleConfirmYes = () => {
        onClose();
        onConfirmYes();
    };

    return (
        <Dialog title={title} onClose={onClose} style={{ margin: '0 5%', maxWidth: '90%' }}>
            <p className="container-modal center">{textContent}</p>
            <DialogActionsBar>
                {onConfirmYes ? (
                    <ButtonYesNo onClickYes={handleConfirmYes} onClickNo={onClose} />
                ) : (
                    <ButtonClose onClickClose={onClose} />
                )}
            </DialogActionsBar>
        </Dialog>
    );
};

export default NotificationMessage;
