import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
const languages = ['fr', 'en'];
i18n
    /*see /public/locales*/
    .use(Backend) // https://github.com/i18next/i18next-http-backend
    .use(LanguageDetector) // https://github.com/i18next/i18next-browser-languageDetector
    .use(initReactI18next)
    //options : https://www.i18next.com/overview/configuration-options
    .init({
        load: 'all',
        initImmediate: false,
        lng: 'fr',
        fallbackLng: 'fr',
        debug: false,
        ns: ['common'],
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            useSuspense: false,
        },
    });
export default i18n;