import { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react';
import useFirebaseNotifications from '../hooks/useFirebaseNotifications';
import { INotification } from '../services/notifications/INotification';
import { ApiMsOrganisations } from 'idside-core';

export type GlobalInfoContextType = {
    currentUserId: string;
    orgID: string;
    currentUserName: string;
    alias: string;
    errorContent: any;
    error: boolean;
    accountId: string;
    impersonatorName: string;
    showNavIcon: boolean;
    lastVirtualTeam: string;
    lastVirtualTeamOrganizationId: string;
    setCurrentUserId: (id: string) => void;
    setAlias: (alias: string) => void;
    setOrgID: (orgID: any) => void;
    setCurrentUserName: (name: string) => void;
    setErrorContent: (errorContent: any) => void;
    setError: Dispatch<SetStateAction<boolean>>;
    setAccountId: (accountId: string) => void;
    setImpersonatorName: (impersonatorName: string) => void;
    setShowNavIcon: (showNavIcon: boolean) => void;
    setLastVirtualTeam: (lastVirtualTeam: string) => void;
    setLastVirtualTeamOrganizationId: (lastVirtualTeamOrganizationId: string) => void;
    firebaseNotifications: INotification[] | undefined;
    isMessagesReaded: boolean | string;
    setIsMessagesReaded: (message: boolean | string) => void;
    isConversationActive: number | string;
    setIsConversationActive: (test: number | string) => void;
    securityActive: boolean;
    setSecurityActive: (securityActive: boolean) => void;
    orgSelected: boolean;
    isOrgSelected: (orgSelected: boolean) => void;
    organisationDefault: string | undefined;
    setOrganisationDefault: Dispatch<SetStateAction<string | undefined>>;
    attachmentConfigurations: ApiMsOrganisations.AttachmentConfigurationsResponse | undefined;
    setAttachmentConfigurations: Dispatch<
        SetStateAction<ApiMsOrganisations.AttachmentConfigurationsResponse | undefined>
    >;
    specificErrorMessage: string | undefined;
    setSpecificErrorMessage: Dispatch<SetStateAction<string | undefined>>;
};

export const GlobalInfoContext = createContext<GlobalInfoContextType>({
    currentUserId: '',
    orgID: '',
    currentUserName: '',
    alias: '',
    errorContent: '',
    error: false,
    accountId: '',
    impersonatorName: '',
    showNavIcon: false,
    lastVirtualTeam: '',
    lastVirtualTeamOrganizationId: '',
    setCurrentUserId: () => {},
    setAlias: () => {},
    setOrgID: () => {},
    setCurrentUserName: () => {},
    setError: () => {},
    setErrorContent: () => {},
    setAccountId: () => {},
    setImpersonatorName: () => {},
    setShowNavIcon: () => {},
    setLastVirtualTeam: () => {},
    setLastVirtualTeamOrganizationId: () => {},
    firebaseNotifications: undefined,
    isMessagesReaded: false,
    setIsMessagesReaded: () => {},
    isConversationActive: -1,
    setIsConversationActive: (test: number | string) => {},
    securityActive: false,
    setSecurityActive: () => {},
    orgSelected: false,
    isOrgSelected: () => {},
    organisationDefault: undefined,
    setOrganisationDefault: () => {},
    attachmentConfigurations: undefined,
    setAttachmentConfigurations: () => {},
    specificErrorMessage: undefined,
    setSpecificErrorMessage: () => {},
});

export const GlobalInfoProvider = ({ children }: any) => {
    const [orgID, setOrgID] = useState<string>(sessionStorage.orgID ?? '');
    const [securityActive, setSecurityActive] = useState<boolean>(false);
    const [currentUserName, setCurrentUserName] = useState<string>('');
    const [alias, setAlias] = useState<string>('');
    const [currentUserId, setCurrentUserId] = useState<string>(sessionStorage.currentUserId ?? '');
    const [errorContent, setErrorContent] = useState<string | undefined>(undefined);
    const [specificErrorMessage, setSpecificErrorMessage] = useState<string | undefined>(undefined);
    const [error, setError] = useState<boolean>(false);
    const [accountId, setAccountId] = useState<string>('');
    const [impersonatorName, setImpersonatorName] = useState<string>('');
    const [lastVirtualTeam, setLastVirtualTeam] = useState<string>('');
    const [lastVirtualTeamOrganizationId, setLastVirtualTeamOrganizationId] = useState<string>(
        localStorage.lastVirtualTeamOrganizationId ?? '',
    );
    const [showNavIcon, setShowNavIcon] = useState<boolean>(false);
    const [isMessagesReaded, setIsMessagesReaded] = useState<boolean | string>(false);
    const firebaseNotifications = useFirebaseNotifications();
    const [isConversationActive, setIsConversationActive] = useState<number | string>(-1);
    const [orgSelected, isOrgSelected] = useState<boolean>(false);
    const [organisationDefault, setOrganisationDefault] = useState<string | undefined>(undefined);
    const [attachmentConfigurations, setAttachmentConfigurations] = useState<
        ApiMsOrganisations.AttachmentConfigurationsResponse | undefined
    >(undefined);
    const value = {
        orgID,
        currentUserName,
        alias,
        currentUserId,
        errorContent,
        error,
        accountId,
        impersonatorName,
        showNavIcon,
        lastVirtualTeam,
        lastVirtualTeamOrganizationId,
        setAlias,
        setCurrentUserId,
        setOrgID,
        setCurrentUserName,
        setErrorContent,
        setError,
        setAccountId,
        setImpersonatorName,
        setShowNavIcon,
        setLastVirtualTeam,
        setLastVirtualTeamOrganizationId,
        firebaseNotifications,
        isMessagesReaded,
        setIsMessagesReaded,
        isConversationActive,
        setIsConversationActive,
        securityActive,
        setSecurityActive,
        orgSelected,
        isOrgSelected,
        organisationDefault,
        setOrganisationDefault,
        attachmentConfigurations,
        setAttachmentConfigurations,
        specificErrorMessage,
        setSpecificErrorMessage,
    };

    useEffect(() => {
        setCurrentUserName(sessionStorage.currentUserName);
        setAlias(sessionStorage.alias);
        setAccountId(sessionStorage.accountId);
        setLastVirtualTeam(localStorage.lastVirtualTeam);
        setSecurityActive(sessionStorage.secActive ? sessionStorage.secActive : false);
    }, []);

    useEffect(() => {
        if (alias) {
            sessionStorage.currentUserId = currentUserId;
            sessionStorage.currentUserName = currentUserName;
            sessionStorage.alias = alias.toUpperCase();
            localStorage.lastVirtualTeam = lastVirtualTeam;
            localStorage.lastVirtualTeamOrganizationId = lastVirtualTeamOrganizationId;
        }
    }, [alias, currentUserName, currentUserId, lastVirtualTeam, lastVirtualTeamOrganizationId]);

    return <GlobalInfoContext.Provider value={value}>{children}</GlobalInfoContext.Provider>;
};
