/* eslint-disable max-lines-per-function */
import { Dispatch, SetStateAction, useContext } from 'react';
import ConversationSelector from '../ConversationSelector/ConversationSelector';
import './conversationSelectorList.css';
import { GlobalInfoContext } from '../../Contexts/globalInfoContext';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { ApiMsConversations } from 'idside-core';
interface ConversationSelectorListProps {
    onSearchSelected: string | undefined;
    conversations: ApiMsConversations.ConversationResponse[];
    loading: boolean;
    updateActiveConversation: (id: string) => void;
    setLoading: Dispatch<SetStateAction<boolean>>;
    setConversations: Dispatch<SetStateAction<ApiMsConversations.ConversationResponse[]>>;
}

export default function ConversationSelectorList({
    onSearchSelected,
    conversations,
    loading,
    updateActiveConversation,
    setLoading,
    setConversations,
}: ConversationSelectorListProps) {
    const { isConversationActive } = useContext(GlobalInfoContext);

    return (
        <ul className="cr-sidebar" data-testid="cr-sidebar">
            {loading ? (
                <LoadingSpinner />
            ) : (
                conversations &&
                conversations.map((conversation: any) => {
                    return (
                        <ConversationSelector
                            received={false}
                            key={conversation.id}
                            //onSelected={onConversationSelected}
                            onSearchSelected={onSearchSelected}
                            setConversations={setConversations}
                            setLoading={setLoading}
                            isActive={conversation.id === isConversationActive}
                            updateActiveConversation={() => updateActiveConversation(conversation.id)}
                            allMessagesReaded={conversation.allMessagedReaded}
                            {...conversation}
                        />
                    );
                })
            )}
        </ul>
    );
}
