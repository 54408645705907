export interface IDownlaodFileInfo {
    fileName: string;
    fileExt: string;
}

const downlaodFileInfo = (fileNamewithExt: string): IDownlaodFileInfo => {
    const lastDotIndex = fileNamewithExt.lastIndexOf('.');

    if (lastDotIndex !== -1) {
        const fileNameWithoutExtension = fileNamewithExt.slice(0, lastDotIndex);
        const fileExtension = fileNamewithExt.slice(lastDotIndex + 1);

        return { fileName: fileNameWithoutExtension, fileExt: fileExtension };
    } else {
        return { fileName: '', fileExt: '' };
    }
};

export function ConvertSizeToMB(size: number) {
    return size / (1024 * 1024);
}

export default downlaodFileInfo;
