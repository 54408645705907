import Logo from '../assets/notificationLogo.png';
import { MessagePayload, Unsubscribe } from 'firebase/messaging';
import { INotification } from '../services/notifications/INotification';
import { VisualNotification_NewMessage } from '../services/notifications/NotificationType';
import {
    createDeviceApi,
    WEB_SERVICE_APPLICATION_ID,
    createNotificationsBasicApi,
    getDeviceToken,
    ApiMsConversations,
    buildLogsAndPostToIndexedDB,
    settings,
    EchoAuth,
} from 'idside-core';
import Keycloak from 'keycloak-js';

export const NewMessageNotificationType = 'nouveauMessage';

export const NotificationPermissionGranted = 'granted';

export const ReadMessageNotificationType = 'messageLu';

export const ReceivedMessageNotificationType = 'messageTransmis';

export const NotificationStatusChanged = 'userEdition';
export interface IfirebaseNotifications {
    payload: MessagePayload;
    unsubscribe: Unsubscribe;
}

export function enableBrowserNotification() {
    const NotificationProperty = 'Notification';
    const NotificationPermissionDefault = 'default';

    // Let's check if the browser supports notifications
    if (!(NotificationProperty in window)) {
        console.log('This browser does not support desktop notification');
    } else if (Notification.permission === NotificationPermissionDefault) {
        //Ask permission for notification
        Notification.requestPermission().then((permission) => {
            // If the user accepts, let's create a notification
            if (permission === NotificationPermissionGranted) {
                new Notification('id.texto', {
                    body: 'Vous acceptez les notifications',
                    icon: `${Logo}`,
                });
            }
        });
    }

    if (Notification.permission !== NotificationPermissionGranted) {
        console.log('Warning: Notifications are currently denied!');
    }
}

export function hasSilentNotification(firebaseNotifications: INotification[] | undefined) {
    return (
        firebaseNotifications !== undefined &&
        firebaseNotifications.find((notif: INotification) => notif.silent === true)
    );
}

export function hasConversationChangedSilentNotification(
    firebaseNotifications: INotification[] | undefined,
    conversationId: string,
) {
    return firebaseNotifications?.find(
        (notif: INotification) =>
            notif.silent === true &&
            notif.data !== undefined &&
            JSON.parse(notif.data)['conversationId'] === conversationId,
    );
}

export function hasNewMessageSilentNotification(firebaseNotifications: INotification[] | undefined) {
    return firebaseNotifications?.find(
        (notif: INotification) =>
            notif.silent === true && JSON.parse(notif.data!)['type'] === NewMessageNotificationType,
    );
}

export function hasVisualNotification(firebaseNotifications: INotification[] | undefined) {
    return (
        firebaseNotifications !== undefined &&
        firebaseNotifications.find((notif: INotification) => notif.silent === false)
    );
}

export function isVisualNotification(firebaseNotification: INotification | undefined) {
    return firebaseNotification !== undefined && firebaseNotification.silent === false;
}

export function hasBasicNotification(notifications: INotification[] | undefined) {
    return (
        notifications !== undefined &&
        notifications.find((notif: INotification) => notif.data === undefined) &&
        notifications.find((notif: INotification) => notif.type !== undefined)
    );
}

export function hasVisualBasicNotification(notifications: INotification[] | undefined) {
    return (
        notifications !== undefined &&
        notifications.find((notif: INotification) => notif.data === undefined) &&
        notifications.find((notif: INotification) => notif.type !== undefined) &&
        notifications.find((notif: INotification) => notif.type === VisualNotification_NewMessage)
    );
}

export function isUnsecureNotification(notification: INotification | undefined) {
    return notification !== undefined && notification.data === undefined;
}

export function getSaveDeviceConfig(
    appVersion: string,
    userId: string,
    notificationToken: string,
    deviceModel: string,
    deviceName: string,
    os: string,
) {
    let obj = {
        appVersion: appVersion,
        userId: userId,
        notificationToken: notificationToken,
        deviceModel: deviceModel,
        deviceName: deviceName,
        os: os,
    };
    return obj;
}

export function hasNewMessageNotioficationInConversation(
    firebaseNotifications: INotification[] | undefined,
    conversationId: string,
) {
    let isNewMessage: boolean = false;

    firebaseNotifications?.forEach((message: INotification | undefined) => {
        if (message?.silent === true) {
            if (JSON.parse(message.data!)['conversationId'] === conversationId) {
                if (JSON.parse(message.data!)['type'] === NewMessageNotificationType) {
                    isNewMessage = true;
                }
            }
        }
    });

    return isNewMessage;
}

export function hasReadSpecificMessageByUserNotification(
    firebaseNotifications: INotification[] | undefined,
    currentUserId: string,
    message: ApiMsConversations.MessageResponse | undefined,
): boolean {
    let readSpecificMessageByUserNotification = firebaseNotifications?.find(
        (notification: INotification | undefined) => {
            let messageId: string = '';
            let cibleId: string = '';
            if (notification?.data !== undefined) {
                if (notification.silent === true) {
                    const notificationType = JSON.parse(notification.data)['type'];
                    if (notificationType === ReadMessageNotificationType) {
                        messageId = JSON.parse(notification.data)['messageId'];
                        cibleId = JSON.parse(notification.data)['cibleId'];
                        if (messageId !== undefined) {
                            if (message !== undefined) {
                                if (message.id === messageId) {
                                    const currentUserRecipient = message.recipients?.find(
                                        (recipient: ApiMsConversations.MessageRecipientResponse) => {
                                            if (recipient.id === currentUserId && recipient.id === cibleId) {
                                                return true; //Found the current user in the list of recipients
                                            }
                                        },
                                    );

                                    if (currentUserRecipient !== undefined) {
                                        return true; //Found a read message status by the current user for the specified message
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
    );

    return readSpecificMessageByUserNotification !== undefined;
}

export function hasReadMessageByUserNotification(
    notifications: INotification[] | undefined,
    currentUserId: string,
): boolean {
    let notificationType: string;
    let cibleId: string;

    const readMessageByUserNotification = notifications?.find((notification: INotification | undefined) => {
        if (notification?.data !== undefined) {
            if (notification?.silent === true) {
                notificationType = JSON.parse(notification.data)['type'];
                if (notificationType === ReadMessageNotificationType) {
                    cibleId = JSON.parse(notification.data)['cibleId'];
                    if (cibleId === currentUserId) {
                        return notification;
                    }
                }
            }
        }
    });

    return readMessageByUserNotification !== undefined;
}

export function isStatusChanged(firebaseNotifications: INotification[] | undefined, recipientInfo: any[]) {
    let statusChange: boolean = false;

    firebaseNotifications?.forEach((message: INotification | undefined) => {
        if (message?.silent === true) {
            if (
                recipientInfo.find((recipient: any) => {
                    return recipient?.id === JSON.parse(message.data!)['editedUserId'];
                })
            ) {
                if (JSON.parse(message.data!)['type'] === NotificationStatusChanged) {
                    statusChange = true;
                }
            }
        }
    });

    return statusChange;
}

export async function deleteNotificationDevice(
    deviceToken: string,
    orgId: string,
    userId: string,
    authInstance: Keycloak | EchoAuth | null,
) {
    const start = new Date().getTime();
    if (deviceToken !== '' && orgId !== '') {
        try {
            await createDeviceApi(orgId, authInstance!).deleteDeviceV2(orgId, deviceToken, userId); //TODO: currentOrganisationId will need to take into account changing organization
        } catch (err: any) {
            await buildLogsAndPostToIndexedDB(
                err.message,
                err.stack,
                new Date().getTime() - start,
                settings.appId,
                window.location.origin,
                err.config.headers.trackingId,
                err.config[`axios-retry`].retries,
                authInstance?.sessionId!,
                userId,
                err.config.headers.deviceId,
                err.config.headers.organisationId,
                err.config.headers.provider,
                settings.dbName,
                settings.storeIdTexto,
            );
            //Trap this error because it is not blocking
            console.log(err);
        }
    }
}

export async function updateNotificationDevice(
    userId: string,
    orgId: string,
    authInstance: Keycloak | EchoAuth | null,
) {
    const start = new Date().getTime();
    try {
        const deviceToken = getDeviceToken();

        if (deviceToken !== '') {
            if (orgId !== '') {
                const OS_WEB = 'Web';

                if (userId !== '') {
                    //Clear existing notifications for this user. We don't actually need them but fetching the
                    //result will have the side-effect of removing them. We do this because we just
                    //connected and therefore:
                    //  1) Date notifications are not useful in this context, since we will be updating everything anyway
                    //  2) Alert (visual notifications) are not useful since they were sent while we were not connected (or at least not as this user)
                    await createNotificationsBasicApi(authInstance!, orgId).getAllBasicNotifications(deviceToken); //TODO: currentOrganisationId will need to take into account changing organization

                    // Register new user to device

                    const saveDeviceConfig = getSaveDeviceConfig(
                        process.env.REACT_APP_VERSION ?? '0.0.0',
                        userId,
                        deviceToken!,
                        navigator.userAgent,
                        navigator.platform,
                        OS_WEB,
                    );

                    await createDeviceApi(orgId, authInstance!).saveDevice(
                        orgId,
                        WEB_SERVICE_APPLICATION_ID,
                        saveDeviceConfig,
                    ); //TODO: currentOrganisationId will need to take into account changing organization
                }
            }
        } else {
            console.log('Erreur de génération du token');
        }
    } catch (err: any) {
        await buildLogsAndPostToIndexedDB(
            err.message,
            err.stack,
            new Date().getTime() - start,
            settings.appId,
            window.location.origin,
            err.config.headers.trackingId,
            err.config[`axios-retry`].retries,
            authInstance?.sessionId!,
            userId,
            err.config.headers.deviceId,
            err.config.headers.organisationId,
            err.config.headers.provider,
            settings.dbName,
            settings.storeIdTexto,
        );
        console.log(err);
    }
}
