import { UserTypeEnum } from 'idside-core';

export const InterlocutorType = {
    Doctor: UserTypeEnum.Doctor,
    Other: UserTypeEnum.Other,
    VirtualTeam: UserTypeEnum.VirtualTeam,
    GroupDiscussion: 'GROUP_DISCUSSION',
} as const;

export type InterlocutorType = (typeof InterlocutorType)[keyof typeof InterlocutorType];
