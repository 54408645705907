import { useTranslation } from 'react-i18next';

interface propsButtonYesNo {
    onClickYes: any;
    onClickNo: any;
}

export default function ButtonYesNo(props: propsButtonYesNo) {
    const [t] = useTranslation();
    return (
        <>
            <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={props.onClickYes}>
                {t('button.yes')}
            </button>

            <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={props.onClickNo}>
                {t('button.no')}
            </button>
        </>
    );
}
