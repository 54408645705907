/* eslint-disable react-hooks/rules-of-hooks */
import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import './i18n';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { GlobalInfoProvider } from './Contexts/globalInfoContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import reportWebVitals from './reportWebVitals';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { NotificationsProvider } from './Contexts/notificationsContext';
import { AuthProvider } from 'idside-core';

import './index.css';
import { retryFunc } from './common/retryFunction';

import RenderOnInitialized from './component/global/RenderOnInitialized';
// const commonJsonFR = require('./locales/fr/common.json');
// const glossaryJsonFR = require('./locales/fr/glossary.json');
// const messageJsonFR = require('./locales/fr/message.json');
// const userJsonFR = require('./locales/fr/user.json');
// const validationJsonFR = require('./locales/fr/validation.json');
const queryClient = new QueryClient();

const root = createRoot(document.getElementById('root') as HTMLElement);
const renderApp = () =>
    root.render(
        <AuthProvider>
            <RenderOnInitialized>
                <QueryClientProvider client={queryClient}>
                    <Router>
                        <GlobalInfoProvider>
                            <NotificationsProvider>
                                <App />
                            </NotificationsProvider>
                        </GlobalInfoProvider>
                    </Router>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </RenderOnInitialized>
        </AuthProvider>,
    );

renderApp();
// retryFunc(AuthService.initKeycloak, [renderApp]);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(////console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
