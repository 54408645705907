import { Dispatch, SetStateAction } from 'react';
import { EchoAuth } from 'idside-core';
import Keycloak from 'keycloak-js';
import { Document } from '../component/Message/Message';

function getActiveIndex(scrollViewRef: React.MutableRefObject<any>): number {
    const elements = Array.from(scrollViewRef.current.element.querySelectorAll('.k-scrollview-view'));

    return elements.findIndex((viewElement: any) => viewElement.getAttribute('aria-hidden') === 'false');
}

export function registerInoutHandlers(scrollViewRef: React.MutableRefObject<any>, onScrollViewUserInput: () => void): void {
    if (scrollViewRef) {
        const element = scrollViewRef.current?.element;

        if (element) {
            element.addEventListener('click', () => {
                onScrollViewUserInput();
            });

            element.addEventListener('keydown', () => {
                onScrollViewUserInput();
            });
        }
    }
}

export function loadCurrentImageIfNeeded(
    scrollViewRef: React.MutableRefObject<any>, 
    scrollViewImageList: Document[] | undefined, 
    setScrollViewImageList: Dispatch<SetStateAction<Document[]>>, 
    loadDocumentDataIfNeeded: (
        documents: Document[], 
        index: number, 
        currentOrganisationId: string,
        currentUserId: string,
        authInstance: Keycloak | EchoAuth
    ) => Promise<Document[]>,
    organisationId: string, 
    userId: string, 
    authInstance: Keycloak | EchoAuth
) {
    setTimeout(async () => {
        if (scrollViewImageList) {
            const activeIndex: number = getActiveIndex(scrollViewRef);

            if (activeIndex >= 0) {
                let documentImage = scrollViewImageList[activeIndex];
                if (documentImage?.data === undefined) {
                    setScrollViewImageList(
                        await loadDocumentDataIfNeeded(scrollViewImageList, activeIndex, organisationId, userId, authInstance)
                    );
                }
            }
        }
    });
}
