import { Button } from '@progress/kendo-react-buttons';
import { useNavigate } from 'react-router';
import './backbutton.css';
import { SvgIcon } from '@progress/kendo-react-common';
import { caretAltLeftIcon } from '@progress/kendo-svg-icons';

interface BackButtonProps {
    text: string;
    styleClass?: string;
    type?: 'button' | 'submit' | 'reset' | undefined;
}

function BackButton({ text, styleClass, type = 'button' }: BackButtonProps) {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    return (
        <Button
            type={type}
            onClick={goBack}
            className={`k-button k-button-md k-button-rectangle k-button-solid k-button-solid-base k-rounded-md ${styleClass}`}>
            <SvgIcon className="back-arrow" icon={caretAltLeftIcon}></SvgIcon>
            {text}
        </Button>
    );
}

export default BackButton;
