import { Dispatch, SetStateAction } from 'react';
import {
    WEB_SERVICE_APPLICATION_ID,
    createUsersApi,
    buildLogsAndPostToIndexedDB,
    settings,
    EchoAuth,
} from 'idside-core';
import { formatDateHoursWithTextMonth } from './messageInfoFormat';
import { IRecipient } from '../component/Conversation/IConversation';
import { getAvatarImagePath } from './avatarPicture';
import Keycloak from 'keycloak-js';
export async function fetchAvailabilityData(
    recipients: any,
    orgID: string,
    kcToken: string = '',
    setError: any,
    setErrorContent: any,
    setShowUnavailable: any,
    setAvailabilityDate: any,
    setIsDeleted: Dispatch<SetStateAction<boolean | undefined>>,
    currentUserId: string,
    setPhotoURL: Dispatch<SetStateAction<string>>,
    authInstance: Keycloak | EchoAuth | null,
) {
    const fetchAvailableData = async (recipient: any) => {
        const start = new Date().getTime();
        const todayIsoString = new Date().toISOString();

        try {
            let isUnavailable = false;

            const userSummary = await createUsersApi(authInstance!, orgID).getUserSummaryById(recipient.id, orgID);
            if (userSummary.data.isDeleted === false) {
                const result = await createUsersApi(authInstance!, orgID).getAttributes(
                    orgID,
                    WEB_SERVICE_APPLICATION_ID,
                    recipient.id,
                );
                if (result.data.attributes) {
                    let jsonRecipients = await JSON.parse(result.data.attributes);
                    if (
                        jsonRecipients.nonAvailabilityDate !== undefined &&
                        jsonRecipients.nonAvailabilityDate !== 'null'
                    ) {
                        const unavailDate = jsonRecipients.nonAvailabilityDate;
                        const formattedUnavailableDate = formatDateHoursWithTextMonth(
                            new Date(jsonRecipients.nonAvailabilityDate),
                        );
                        setAvailabilityDate(formattedUnavailableDate);
                        isUnavailable = unavailDate > todayIsoString;
                    }
                }
            }

            setIsDeleted(userSummary.data.isDeleted);
            setPhotoURL(getAvatarImagePath(userSummary.data.type, userSummary.data.isDeleted));
            setShowUnavailable(isUnavailable);
        } catch (err: any) {
            await buildLogsAndPostToIndexedDB(
                err.message,
                err.stack,
                new Date().getTime() - start,
                settings.appId,
                window.location.origin,
                err.config.headers.trackingId,
                err.config[`axios-retry`].retries,
                authInstance?.sessionId!,
                currentUserId,
                err.config.headers.deviceId,
                err.config.headers.organisationId,
                err.config.headers.provider,
                settings.dbName,
                settings.storeIdTexto,
            );
            //TODO: Create component to show errors to user
            setShowUnavailable(false);

            console.log('Error obtaining', err);
            setError(true);
            setErrorContent(err);
        }
    };
    //Hardcoded value
    if (recipients?.length < 3) {
        recipients.forEach((recipient: IRecipient) => {
            if (recipient.id !== currentUserId) {
                if (recipient.id) {
                    fetchAvailableData(recipient);
                }
            }
        });
    }
    //TODO: Manage error or special cases
}
