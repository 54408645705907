import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useTranslation } from 'react-i18next';

type TimeoutMessageProps = {
    onClose: () => void;
};

const TimeoutMessage = ({ onClose }: TimeoutMessageProps) => {
    const [t] = useTranslation(['common', 'validation']);

    const toggleYes = () => {
        onClose();
    };

    const togglePopup = () => {
        onClose();
    };
    return (
        <Dialog
            className="timeout-popup"
            id="popup-dialog-timeout"
            title={t('confirmation', { ns: 'validation' })}
            onClose={togglePopup}>
            <p
                data-testid="popup-text-timeout"
                style={{
                    margin: '25px',
                    textAlign: 'center',
                }}>
                {t('timeout', { ns: 'validation' })}
            </p>
            <DialogActionsBar>
                <button
                    data-testid="popup-button"
                    style={{ color: 'white', width: '100%' }}
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    onClick={toggleYes}>
                    {t('button.ok')}
                </button>
            </DialogActionsBar>
        </Dialog>
    );
};

export default TimeoutMessage;
