import { useContext, useEffect, useState } from 'react';
import { getDeviceToken, useAuth } from 'idside-core';
import { GlobalInfoContext } from '../Contexts/globalInfoContext';
import { getAllBasicNotifications, getAllNotifications } from '../services/notifications/notificationsService';
import { INotification } from '../services/notifications/INotification';

export interface INotifEcb {
    id: string;
    appId: string;
    clientId: string;
    clientResponse: string;
    status: string;
    creationDate: string;
    to: string;
    title: string;
    body: string;
    data: any;
    silent: boolean;
    collapseKey: string;
    alarm: string;
    os: string;
}

const useFirebaseNotifications = () => {
    //const [onMessagePayload, setOnMessagePayload] = useState<{ payload: MessagePayload; unsubscribe: Unsubscribe }>();
    const { orgID, securityActive } = useContext(GlobalInfoContext);
    const [messagePayload, setMessagePayload] = useState<INotification[]>();
    const [firebaseNotificationsToken, setFirebaseNotificationsToken] = useState<string | void>('');
    const { isLoggedIn, authInstance } = useAuth();
    //const { currentUserId } = useContext(GlobalInfoContext);

    useEffect(() => {
        const getfirebaseNotificationsToken = () => {
            const currentfirebaseNotificationsToken = getDeviceToken();
            //console.log('currentfirebaseNotificationsToken', currentfirebaseNotificationsToken);
            setFirebaseNotificationsToken(currentfirebaseNotificationsToken!);
        };
        getfirebaseNotificationsToken();
    }, []);

    useEffect(() => {
        const notif = setInterval(async () => {
            if (firebaseNotificationsToken) {
                let notifications = undefined;
                if (isLoggedIn()) {
                    const organisationId: string | null = orgID ? orgID : sessionStorage.getItem('orgID');
                    const isSecurityActive: boolean = securityActive ? securityActive : sessionStorage.secActive;
                    if (isSecurityActive) {
                        notifications = await getAllNotifications(
                            organisationId!,
                            firebaseNotificationsToken,
                            authInstance,
                        );
                    }
                } else if (!isLoggedIn() && !orgID) {
                    //Currently in disconnected mode as a Virtual Team
                    const lastOrganisationId: string | null = localStorage.getItem('lastVirtualTeamOrganizationId');
                    if (lastOrganisationId) {
                        notifications = await getAllBasicNotifications(
                            lastOrganisationId!,
                            firebaseNotificationsToken,
                            authInstance,
                        );
                    }
                }

                if (notifications) {
                    console.log(JSON.stringify(notifications));
                    setMessagePayload(notifications);
                }

                //TODO ajouter un array dynamique qui vient se mettre à jour plutôt que de remplacer (principe de file)
            }
        }, 5000);
        return () => clearInterval(notif);
    }, [firebaseNotificationsToken, securityActive]);

    //console.log('messagePayload => ', messagePayload);
    return messagePayload;
    // onMessageListener().then((response: any) => {
    //     //TODO: Remove once firebase is stable -->
    //     let notificationType: string | undefined = response?.payload.collapseKey;
    //     if (isSilentNotification(response)) {
    //         console.log('Data notification: ' + notificationType);
    //     } else {
    //         console.log('Visual notification: ' + notificationType);
    //     }
    //     // <-- Remove once firebase is stable
    //     //setOnMessagePayload(response);
    //     setOnMessagePayload(notifFireBase);
    // });
    // return onMessagePayload;
};

export default useFirebaseNotifications;
