import './unavailableAvatar.css';
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';

const UnavailableAvatar = () => {
    return (
        <div className="unavailable-avatar" data-testid="unavailable-avatar">
            <SvgIcon className="lock-bubble" icon={svgIcons.commentIcon} flip="horizontal" size="medium" />
            <SvgIcon className="lock" icon={svgIcons.lockIcon} size="medium" />
        </div>
    );
};

export default UnavailableAvatar;
