import { useAuth } from 'idside-core';
import { Link } from 'react-router-dom';

// eslint-disable-next-line max-lines-per-function
export default function External() {
    const { isAuthenticated, getToken } = useAuth();
    return (
        <div>
            <h1>Welcome to external page</h1>
            <h1>isAuthenticated {isAuthenticated ? 'true' : 'false'}</h1>
            <h1>getToken {getToken()}</h1>

            <Link to={'/'}>Go to home </Link>
        </div>
    );
}
