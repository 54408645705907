import React from 'react';

export default function GlobalSpinner() {
    return (
        <div className="loader-login-central">
            <div className="k-loader k-loader-md k-loader-error k-loader-spinner-3">
                <div className="k-loader-canvas">
                    <span className="k-loader-segment"></span>
                    <span className="k-loader-segment"></span>
                    <span className="k-loader-segment"></span>
                </div>
            </div>
        </div>
    );
}
