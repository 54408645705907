/* eslint-disable max-lines-per-function */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import './messageDocument.css';
import { SVGIcon, SvgIcon } from '@progress/kendo-react-common';
import { preventPropagationToParent } from '../../common/messageUtil';
import { APPLICATION, REGEX_EXTRACT_PREFIX } from '../commonData';
import getFileExtension from '../../common/FileExtension';
import { Document } from '../Message/Message';
import { getFileTypeIcon } from '../../common/downloadInput';
import { GlobalInfoContext } from '../../Contexts/globalInfoContext';
import { createConversationsDocumentsApi, useAuth } from 'idside-core';
import { downloadFile } from '../../common/DownloadFile';

export type messageDocumentProps = {
    doc: Document;
    isCurrentUserMessage: boolean;
    isInputField?: boolean;
    messageId?: string; //Message Id is null when we are in the context of writing a new message with a document
    isDownloadAvailable: boolean;
};

const MessageDocument = ({
    doc,
    isCurrentUserMessage,
    isInputField,
    messageId,
    isDownloadAvailable,
}: messageDocumentProps) => {
    const { authInstance } = useAuth();
    const { orgID: currentOrganisationId, currentUserId } = useContext(GlobalInfoContext);
    const [iconTypeState, setIconTypeState] = useState<SVGIcon>({ content: '', name: '', viewBox: '0 0 512 512' });
    const [truncatedDocFileName, setTruncatedDocFileName] = useState<string>(doc.filename ?? '');
    const maxLengthFileNameWithoutEspaces = 17;
    const [singleDocument, setSingleDocument] = useState<Document>(doc);

    const MessageDocumentDownloader = (docToDownload: Document) => {
        try {
            if (!docToDownload.data) {
                throw new Error('Document data is missing');
            }

            const base64Data = docToDownload.data.replace(REGEX_EXTRACT_PREFIX, '');

            const uint8Array = new Uint8Array(
                atob(base64Data)
                    .split('')
                    .map((char) => char.charCodeAt(0)),
            );

            const blob = new Blob([uint8Array], {
                type: `${APPLICATION}${getFileExtension(docToDownload.mimeType)}`,
            });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            if (docToDownload.filename) {
                link.download = docToDownload.filename;
            }

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error(`Error: ${error}`);
        }
    };

    const handleDownloadFile = (e: any) => {
        if (isDownloadAvailable) {
            preventPropagationToParent(e);
            onDownloadDocumentClicked();
        }
    };

    useEffect(() => {
        if (singleDocument.filename) {
            const fileExtension = getFileExtension(singleDocument.filename);
            setIconTypeState(getFileTypeIcon(fileExtension) ?? iconTypeState);

            if (
                singleDocument?.filename.length > maxLengthFileNameWithoutEspaces &&
                !singleDocument?.filename.includes(' ')
            ) {
                setTruncatedDocFileName(singleDocument?.filename.slice(0, maxLengthFileNameWithoutEspaces) + '...');
            }
        }
    }, [singleDocument.filename]);

    async function onDownloadDocumentClicked() {
        const documentId = singleDocument.id;

        if (!messageId || !documentId) {
            throw new Error('Invalid document');
        }

        createConversationsDocumentsApi(authInstance, currentOrganisationId).postDocumentExport({
            userId: currentUserId,
            messageId,
            documentId,
        });

        const downloadedDoc = (await downloadFile(
            singleDocument,
            currentOrganisationId,
            currentUserId,
            authInstance,
        )) as Document;
        //TODO: Add event to update document data in parent component

        setSingleDocument(downloadedDoc);

        MessageDocumentDownloader(downloadedDoc as Document);
    }

    return (
        <>
            {isInputField ? (
                <div className={'input-message-doc-container'} data-testid="input-doc-container">
                    <div className={'message-download'}>
                        {iconTypeState && (
                            <div className={`input-message-doc-icon ${iconTypeState?.name}`}>
                                <SvgIcon className="" icon={iconTypeState} size="medium" />
                            </div>
                        )}
                        <div className={'message-doc-title'} data-testid="input-doc-filename">
                            {truncatedDocFileName}
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    className={'message-doc-container'}
                    data-testid="message-doc-container"
                    onClick={handleDownloadFile}>
                    <div
                        className={isCurrentUserMessage ? 'message-download' : 'other-message-download'}
                        data-testid="message-download">
                        {iconTypeState && (
                            <div className={`message-doc-icon ${iconTypeState?.name}`}>
                                <SvgIcon className="" icon={iconTypeState} size="medium" />
                            </div>
                        )}
                        <div
                            className={isCurrentUserMessage ? 'message-doc-title' : 'other-message-doc-title'}
                            data-testid="message-doc-filename">
                            {truncatedDocFileName}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default MessageDocument;
