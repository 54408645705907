/* eslint-disable react-hooks/rules-of-hooks */

const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));

export async function retryFunc<T extends (...args0: any[]) => any>(
    func: T, // T = fonction
    args: Parameters<T>, // args = paramètre de la fonction appeller
    essaisMax: number = 3, // nombre d'essais maximum,
    tryCount = 1,
): Promise<Awaited<ReturnType<T>>> {
    const essaisCourrant = typeof tryCount === 'number' ? tryCount : 1;
    try {
        const result = await func(...args);
        return result;
    } catch (error: any) {
        // Si l'erreur est retourner par le back-end
        if (error.status >= 500 || error.code === 'ERR_NETWORK') {
            //Ajouter un logger pour permettre de savoir si on doit s'attarder sur une fonction en particulier.
            console.log(`Retry ${essaisCourrant} Func ${func.name}`);
            if (essaisCourrant >= essaisMax) {
                //Ajouter un alerte si on a un erreur dans l'application a partir d'ici.
                console.log(`Toutes les ${essaisMax} essais ont échoués ${func.name}`);
                throw error;
            }

            await wait(2500 * essaisCourrant);

            do {
                return retryFunc(func, args, essaisMax, essaisCourrant + 1);
            } while (essaisCourrant < essaisMax);
        } else {
            throw error;
        }
    }
}
