import { useAuth } from 'idside-core';

type Props = {
    children?: any;
};
const RenderOnAuthenticated = ({ children }: Props) => {
    const { isAuthenticated } = useAuth();
    return isAuthenticated ? children : null;
};

export default RenderOnAuthenticated;
