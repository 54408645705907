import { createNotificationsApi, createNotificationsBasicApi, ApiNS, EchoAuth } from 'idside-core';
import { AxiosResponse } from 'axios';
import { INotification } from './INotification';
import Keycloak from 'keycloak-js';
export async function getAllBasicNotifications(
    organisationId: string,
    deviceToken: string,
    authInstance: Keycloak | EchoAuth | null,
): Promise<INotification[]> {
    const response: AxiosResponse<ApiNS.NotificationBasicResponse[], any> = await createNotificationsBasicApi(
        authInstance!,
        organisationId,
    ).getAllBasicNotifications(deviceToken);

    const notificationsBasicResponses = response.data;

    let notifications: INotification[] = new Array<INotification>();

    if (notificationsBasicResponses !== undefined) {
        notificationsBasicResponses.forEach((notificationResponse) => {
            let notification: INotification = { type: notificationResponse.type };
            notifications.push(notification);
        });
    }

    return notifications;
}

export async function getAllNotifications(
    organisationId: string,
    deviceToken: string,
    authInstance: Keycloak | EchoAuth | null,
): Promise<INotification[]> {
    const response: AxiosResponse<ApiNS.NotificationResponse[], any> = await createNotificationsApi(
        authInstance!,
        organisationId,
    ).getAllNotifications(deviceToken);

    const notificationsResponses = response.data;

    let notifications: INotification[] = new Array<INotification>();

    if (notificationsResponses !== undefined) {
        notificationsResponses.forEach((notificationResponse) => {
            let notification: INotification = {
                id: notificationResponse.id,
                appId: notificationResponse.appId,
                client: notificationResponse.client,
                clientResponse: notificationResponse.clientResponse,
                status: notificationResponse.status,
                creationDate: notificationResponse.creationDate,
                to: notificationResponse.to,
                title: notificationResponse.title,
                body: notificationResponse.body,
                data: notificationResponse.data,
                silent: notificationResponse.silent,
                collapseKey: notificationResponse.collapseKey,
                alarm: notificationResponse.alarm,
                os: notificationResponse.os,
            };
            notifications.push(notification);
        });
    }

    return notifications;
}
