import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

interface IPageContainerProps {
    loading: boolean;
    children: JSX.Element;
}

export default function PageContainer({ loading, children }: IPageContainerProps) {
    if (loading)
        return (
            <div className="screen-center">
                <LoadingSpinner />
            </div>
        );

    return children;
}
