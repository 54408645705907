/* eslint-disable max-lines-per-function */
import { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { GlobalInfoContext } from './globalInfoContext';
import { settings, createConversationUsersUnsecuredApi, buildLogsAndPostToIndexedDB, useAuth } from 'idside-core';
import {
    hasNewMessageSilentNotification,
    isVisualNotification,
    hasVisualNotification,
    NotificationPermissionGranted,
    isUnsecureNotification,
    hasBasicNotification,
    hasReadMessageByUserNotification,
    hasVisualBasicNotification,
} from '../common/notificationsUtil';
import { useTranslation } from 'react-i18next';
import Logo from '../assets/notificationLogo.png';
import NotificationLogo from '../assets/IdsideLogoNotif.svg';
import RedLogo from '../assets/IdsideLogo.svg';
import { useLocation } from 'react-router-dom';
import { INotification } from '../services/notifications/INotification';

export type NotificationsContextType = {
    hasUnreadMessage: boolean;
    hasReadNewMessage: boolean;
    setHasUnreadMessage: (hasUnreadMessage: boolean) => void;
    sethasReadNewMessage: (c: boolean) => void;
};

export const NotificationsContext = createContext<NotificationsContextType>({
    hasUnreadMessage: false,
    hasReadNewMessage: false,
    setHasUnreadMessage: () => {},
    sethasReadNewMessage: () => {},
});

export const NotificationsProvider = ({ children }: any) => {
    const [t] = useTranslation();
    const [hasUnreadMessage, setHasUnreadMessage] = useState(false);
    const location = useLocation();
    const [hasReadNewMessage, sethasReadNewMessage] = useState(false);
    const [lastVirtualTeamId] = useState(() => localStorage.getItem('lastVirtualTeamId'));
    const value = { hasReadNewMessage, hasUnreadMessage, sethasReadNewMessage, setHasUnreadMessage };
    const {
        orgID,
        currentUserId,
        showNavIcon,
        setError,
        setErrorContent,
        firebaseNotifications,
        lastVirtualTeamOrganizationId,
        securityActive,
    } = useContext(GlobalInfoContext);
    const { authInstance } = useAuth();
    const notificationTimerInSec = settings.notificationTimerInSec;

    //Seulement accessible lorsqu'on a une équipe virtuelle spécifiée
    //const currentUserId = sessionStorage.currentUserId;
    const icon = document.querySelector('.icon-tab') as HTMLAnchorElement;

    const getAlerts = useCallback(
        (abortController: AbortController | undefined) => {
            const start = new Date().getTime();
            //https://msconversations.azurewebsites.net/users/1/alerts
            //FOR PROXY: /users/1/alerts
            const currentContextUserId = securityActive ? currentUserId : lastVirtualTeamId!;
            const currentContextOrganisationId = securityActive ? orgID : lastVirtualTeamOrganizationId;

            if (currentContextUserId && currentContextOrganisationId) {
                createConversationUsersUnsecuredApi(authInstance!)
                    .hasAlerts(currentContextUserId, currentContextOrganisationId, { signal: abortController?.signal })
                    .then((res: any) => {
                        setHasUnreadMessage(res.data.hasAlerts);
                        if (hasReadNewMessage) {
                            sethasReadNewMessage(false);
                        }
                        //TODO: spam alerte
                        if (res.data.hasAlerts) {
                            icon.href = NotificationLogo;
                            document.title = t('applicationName') + t('notification.newMessagePageTitleSuffix');
                            setTimeout(() => {
                                document.title = t('applicationName');
                                icon.href = RedLogo;
                            }, notificationTimerInSec);
                        } else {
                            document.title = t('applicationName');
                        }
                    })
                    .catch(async (err: any) => {
                        await buildLogsAndPostToIndexedDB(
                            err.message,
                            err.stack,
                            new Date().getTime() - start,
                            settings.appId,
                            window.location.origin,
                            err.config.headers.trackingId,
                            err.config[`axios-retry`].retries,
                            authInstance?.sessionId!,
                            currentUserId,
                            err.config.headers.deviceId,
                            err.config.headers.organisationId,
                            err.config.headers.provider,
                            settings.dbName,
                            settings.storeIdTexto,
                        );
                        console.log(err);
                        setError(true);
                        setErrorContent(err);
                    });
            }
        },
        [
            authInstance,
            currentUserId,
            hasReadNewMessage,
            icon,
            lastVirtualTeamId,
            lastVirtualTeamOrganizationId,
            notificationTimerInSec,
            orgID,
            securityActive,
            setError,
            setErrorContent,
            t,
        ],
    );

    useEffect(() => {
        const abortController = new AbortController();
        //Si la sécurité est active (lors de la sélection d'organisme après login)
        //Si la sécurité n'est pas active et qu'on se trouve sur la page de login
        if (securityActive) {
            getAlerts(abortController);
        } else if (!securityActive && location.pathname === '/') {
            getAlerts(abortController);
        }
    }, [getAlerts, location.pathname, securityActive]);

    useEffect(() => {
        const abortController = new AbortController();
        const displayVisualNotifications = () => {
            if (Notification.permission === NotificationPermissionGranted) {
                firebaseNotifications?.forEach((notif: INotification) => {
                    if (isVisualNotification(notif)) {
                        const title =
                            notif.title !== undefined ? notif.title : t('notification.newMessageNotificationTitle');
                        const body =
                            notif.body !== undefined ? notif.body : t('notification.newMessageNotificationText');

                        //console.log('Notification title and body: ' + title + ' -- ' + body);
                        new Notification(title, {
                            body: body,
                            icon: `${Logo}`,
                        });
                    } else if (isUnsecureNotification(notif)) {
                        const title = t('notification.newMessageNotificationTitle');
                        const body =
                            notif.body !== undefined ? notif.body : t('notification.newMessageNotificationText');

                        new Notification(title, {
                            body: body,
                            icon: `${Logo}`,
                        });
                    }
                });
            } else {
                console.log('WARNING: Notifications disabled!');
                //TODO: Show a message inside the application
            }
        };

        if (
            hasNewMessageSilentNotification(firebaseNotifications) ||
            hasReadMessageByUserNotification(firebaseNotifications, currentUserId)
        ) {
            getAlerts(abortController);
        } else if (hasBasicNotification(firebaseNotifications)) {
            getAlerts(abortController);
        }

        if (hasVisualNotification(firebaseNotifications) || hasVisualBasicNotification(firebaseNotifications)) {
            displayVisualNotifications();
        }
    }, [firebaseNotifications]);

    return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};
