import dayjs from 'dayjs';
import { IgetAllMessageData, IRecipient } from '../component/Conversation/IConversation';
import i18n from '../i18n';

function setFirstCharacterLowercase(text: string) {
    return text.charAt(0).toLocaleLowerCase() + text.slice(1);
}

//Obtain the localTimeZone in string format
export function toLocalTimeZoneIsoString(date: Date): string {
    let isoString = '';

    try {
        const pad = function (num: number) {
            return (num < 10 ? '0' : '') + num;
        };

        const tzo: number = -date.getTimezoneOffset();
        const dif: string = tzo >= 0 ? '+' : '-';

        isoString =
            date.getFullYear() +
            '-' +
            pad(date.getMonth() + 1) +
            '-' +
            pad(date.getDate()) +
            'T' +
            pad(date.getHours()) +
            ':' +
            pad(date.getMinutes()) +
            ':' +
            pad(date.getSeconds()) +
            dif +
            pad(Math.floor(Math.abs(tzo) / 60)) +
            ':' +
            pad(Math.abs(tzo) % 60);
    } catch (error) {
        //console.log('Invalid date to format to iso: ', date);
    }

    return isoString;
}

//Format the unavailable date
export function formatDateHoursWithTextMonth(date: Date) {
    return (
        dayjs(date).format(`YYYY-MM-DD`) +
        ` ${i18n.t('at', { ns: 'common' })} ` +
        dayjs(date).format(`HH`) +
        `${i18n.t('hour', { ns: 'common' })}` +
        dayjs(date).format(`mm`)
    );
}

//Format the time to relative date
export function formatToRelativeDate(
    isoDate?: string,
    firstCharacterLowercase: boolean = false,
    addPreposition: boolean = true,
): string {
    if (!isoDate) {
        return ''; //Invalid date
    }

    const isoString = toLocalTimeZoneIsoString(new Date(isoDate));
    const dateString: string = isoString.slice(0, 10);
    const timeString: string = isoString.slice(11, 16);
    const endOfToday = dayjs().endOf('d');
    const daysDifference = endOfToday.diff(isoDate, 'd');

    let formattedDateString: string = '';

    if (daysDifference === 0) {
        formattedDateString = addPreposition
            ? `${i18n.t('beforeHourPreposition', {
                  ns: 'message',
              })} ${timeString}`
            : `${timeString}`;
    } else if (daysDifference === 1) {
        formattedDateString = `${i18n.t('yesterday', {
            ns: 'message',
        })} ${i18n.t('beforeHourPreposition', {
            ns: 'message',
        })} ${timeString}`;
        if (firstCharacterLowercase) {
            formattedDateString = setFirstCharacterLowercase(formattedDateString);
        }
    } else {
        formattedDateString = addPreposition
            ? `${i18n.t('beforeDatePreposition', {
                  ns: 'message',
              })} ${dateString} ${i18n.t('beforeHourPreposition', { ns: 'message' })} ${timeString}`
            : `${dateString} ${timeString}`;
    }

    return formattedDateString;
}

//Format message status for 1 to 1 conversations
export function formatToMessageStatusAndDate(isoReceivedDate?: string, isoReadDate?: string): string {
    // const receivedDate: Date | undefined = receivedDateAsText !== undefined ? new Date(receivedDateAsText) : undefined;
    // const readDate: Date | undefined = readDateAsText !== undefined ? new Date(readDateAsText) : undefined;

    let formattedRelativeDate: string;
    let formattedStatusAndDate: string;

    if (isoReadDate !== undefined) {
        formattedRelativeDate = formatToRelativeDate(isoReadDate, true);
        formattedStatusAndDate = `${i18n.t('read', { ns: 'message' })} ${formattedRelativeDate} `;
    } else if (isoReceivedDate !== undefined) {
        formattedRelativeDate = formatToRelativeDate(isoReceivedDate, true);
        formattedStatusAndDate = `${i18n.t('received', { ns: 'message' })} ${formattedRelativeDate}`;
    } else {
        formattedStatusAndDate = i18n.t('send', { ns: 'message' });
    }

    return formattedStatusAndDate;
}

//Obtain date if we are not the author of the message and if this is not a group conversation
export function obtainInterlocutorReceivedDate(
    message: IgetAllMessageData,
    currentUserId: string,
    isGroupConversation: boolean,
) {
    if (message.authorId === currentUserId && !isGroupConversation) {
        const interlocutorRecipient: IRecipient | undefined = message.recipients.find(
            (recipient: IRecipient) => recipient.id !== currentUserId,
        );

        return interlocutorRecipient?.receivedDate;
    }
}

//Obtain date if we are not the author of the message and if this is not a group conversation
export function obtainInterlocutorReadDate(
    message: IgetAllMessageData,
    currentUserId: string,
    isGroupConversation: boolean,
) {
    if (message.authorId === currentUserId && !isGroupConversation) {
        const interlocutorRecipient: IRecipient | undefined = message.recipients.find(
            (recipient: IRecipient) => recipient.id !== currentUserId,
        );

        return interlocutorRecipient?.readDate;
    }
}

//Obtain the number of person that have read the message
export function getReadCount(messageData: IgetAllMessageData) {
    let readCount: number = 0;
    messageData.recipients.forEach((recipient: IRecipient) => {
        if (recipient.readDate !== undefined) {
            readCount++;
        }
    });
    return readCount;
}
