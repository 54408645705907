import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { settings } from 'idside-core';

export interface IIdleTimerInfo {
    remaining: number;
    warningLogout: boolean;
    activate: () => boolean;
}

const useLogout = (): IIdleTimerInfo => {
    const millisecondsToSeconds = 1000;
    const [remaining, setRemaining] = useState<number>(settings.logoutTimeoutInMilliseconds / millisecondsToSeconds);
    const [warningLogout, setWarningLogout] = useState<boolean>(false);

    const { getRemainingTime, isPrompted, activate } = useIdleTimer({
        events: [
            // 'mousemove',
            // 'touchmove',
            // 'MSPointerMove',
            // 'visibilitychange',
            // 'mousewheel', chrome/edge
            'keydown',
            'wheel',
            'mousedown',
            'touchstart',
            'MSPointerDown',
            'focus',
        ],
        timeout: settings.logoutTimeoutInMilliseconds,
        promptBeforeIdle: settings.logoutTimeoutWarningInMilliseconds,
        crossTab: true,
        leaderElection: true,
        syncTimers: settings.syncTimers,
    });

    useEffect(() => {
        setWarningLogout(isPrompted());
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / millisecondsToSeconds));
        }, 500);

        return () => {
            clearInterval(interval);
        };
    });
    return { remaining, warningLogout, activate };
};

export default useLogout;
