import { PREFIX_DISPLAY_BASE_64, PREFIX_DISPLAY_DATA_IMAGE, mimeToExtension } from '../commonData';

export function getCurrentImage(viewImageList: any) {
    const divElement = document.querySelector('.k-scrollview-wrap') as HTMLElement;
    const currentViewIndex = divElement ? divElement.style.getPropertyValue('--kendo-scrollview-current') : '';
    const currentImage = viewImageList[parseInt(currentViewIndex!) - 1];
    
    return currentImage;
}

const GetMimeTypeDefaultFileExtension = (mimeType: string) => {
    if (mimeType) {
        return mimeToExtension[mimeType][0];
    }
};

const MessageImageDownloader = (viewImageList: any, fileName: string) => {
    // TODO: Need to modify viewImageList to DocumentData[] in refactoring because it affects forward and reply.
    let currentImage = getCurrentImage(viewImageList);

    const link = document.createElement('a');
    link.href =
        PREFIX_DISPLAY_DATA_IMAGE +
        GetMimeTypeDefaultFileExtension(currentImage.mimeType) +
        PREFIX_DISPLAY_BASE_64 +
        currentImage.data;
    link.download = currentImage.filename ?? fileName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export default MessageImageDownloader;
